import { FirebaseOptions } from 'firebase/app'
import { PropsWithChildren, createContext } from 'react'
import { useFirebase } from './Firebase'
import { PwaInstaller, usePwaInstallDialog } from './InstallPwa'
import { useUserNullable } from './UseUser'
import {
  NotificationRegisterer,
  useNotificationsRegistration,
} from './useNotificationsRegistration'

export const pwaContext = createContext<{
  notificationRegisterer?: NotificationRegisterer
  pwaInstaller?: PwaInstaller
}>({})

export function NotificationRegistrationProvider(
  props: PropsWithChildren & { firebaseConfig: FirebaseOptions },
) {
  const firebase = useFirebase(props.firebaseConfig)
  const { user } = useUserNullable({ auth: firebase.auth })
  const notificationRegisterer = useNotificationsRegistration(
    firebase.firebaseApp,
    firebase.firebaseDb,
    user ?? undefined,
  )
  const pwaInstaller = usePwaInstallDialog(localStorage, 'Reclaim Loyalty App')
  return (
    <pwaContext.Provider value={{ notificationRegisterer, pwaInstaller: pwaInstaller }}>
      {props.children}
    </pwaContext.Provider>
  )
}
