import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import CardSkeleton from './CardSkeleton'

const SkeletonHome = () => {
  return (
    <section className='layout h-[100vh]'>
      <TwMainGridWrapper className='h-full'>
        <div
          className={
            'col-span-12 w-full rounded bg-white p-4 shadow-md md:col-span-6 lg:col-span-3'
          }>
          <div className='mx-auto max-w-md space-y-4'>
            {/* List item */}{' '}
            <div className='skeleton h-6 w-1/3 animate-pulse rounded bg-gray-300'></div>
            {[...Array(5)].map((_, index) => (
              <div
                className='flex space-x-4'
                key={index}>
                {/* Avatar Skeleton */}
                <div className='skeleton h-10 w-10 animate-pulse rounded-full bg-gray-300'></div>
                <div className='flex-1 space-y-2'>
                  {/* Name Skeleton */}
                  <div className='skeleton h-4 w-1/2 animate-pulse rounded bg-gray-300'></div>
                  {/* Details Skeleton */}
                  <div className='skeleton h-3 w-1/4 animate-pulse rounded bg-gray-300'></div>
                  {/* Time Skeleton */}
                  <div className='skeleton h-3 w-1/3 animate-pulse rounded bg-gray-300'></div>
                </div>
                <div className='space-y-2 text-right'>
                  {/* Points Skeleton */}
                  <div className='skeleton h-4 w-1/4 animate-pulse rounded bg-gray-300'></div>
                  {/* Status Skeleton */}
                  <div className='skeleton h-3 w-1/3 animate-pulse rounded bg-gray-300'></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <NotificationBox className='col-span-4' /> */}
        <div className='col-span-8 grid grid-cols-1'>
          <div className='col-span-4 rounded bg-white p-4 pb-6 shadow-md'>
            <CardSkeleton />
          </div>
        </div>
      </TwMainGridWrapper>
    </section>
  )
}

export default SkeletonHome
