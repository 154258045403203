import clsx from 'clsx'
import Transition from 'components/Transition'
import { ReactComponent as CupSVG } from 'icons/cup.svg'
import { ReactComponent as PlusSVG } from 'icons/icons-plus.svg'
import { ReactComponent as LogoWhite } from 'icons/logo-white.svg'
import { ReactComponent as UnknownSVG } from 'icons/unknown.svg'
import { TwCardHome, TwTitleCardHome } from 'pages/Home/components/twHome.module'
import { FaPlus } from 'react-icons/fa'
import { ICard } from 'types/card.module'

const Card: React.FC<ICard> = ({
  primaryColor,
  name,
  logo,
  isCreate,
  onClick,
  points,
  secondaryColor,
  isHaveData,
  marked,
  onIncrementClicked,
  onDecrementClicked,
  editable,
  onClaimClicked,
  offers,
}) => {
  return (
    <Transition>
      <div className='flex'>
        <TwCardHome
          className='min-h-[15.438rem] w-full max-w-[25rem] rounded-xl border !p-2.5 shadow-2xl'
          style={{ background: primaryColor }}>
          <div className='grid w-full grid-cols-3 space-x-4 overflow-hidden'>
            <TwTitleCardHome
              className='invert(42%) sepia(59%) saturate(5613%) hue-rotate(332deg) brightness(120%) contrast(90%) col-span-2
                line-clamp-3 overflow-hidden !font-bold !leading-[inherit]'>
              {name || 'Create your rewards program'}
            </TwTitleCardHome>
            <div className='col-span-1 flex items-start justify-end'>
              <div className='h-25 w-28'>
                {(logo && (
                  <img
                    src={logo}
                    alt='logo'
                    className='h-full w-full object-contain'
                  />
                )) || <UnknownSVG className='h-full w-full fill-stroke-gray' />}
              </div>
            </div>
          </div>

          <div className='flex items-end justify-between gap-x-6'>
            {(isCreate && !isHaveData && (
              <button
                onClick={onClick}
                disabled={!isCreate}
                className={'flex h-4 items-center gap-x-1 text-sm'}>
                <PlusSVG
                  className='fill-stroke-gray-500'
                  width={20}
                />
                Create
              </button>
            )) || (
                <div className={`flex items-center gap-x-2 ${points.length < 11 && 'mb-8'}`}>
                  <div className='flex flex-wrap justify-center gap-2'>
                    {points.map((item, index) => (
                      <div
                        className={clsx(
                          `relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-light-secondary
                        bg-white text-sm font-medium text-gray-800 transition-colors duration-300`,
                          { 'hover:cursor-pointer hover:bg-slate-300': editable },
                        )}
                        style={{ borderColor: secondaryColor }}
                        onClick={() =>
                          marked && marked > index ?
                            onDecrementClicked?.()
                            : onIncrementClicked?.()
                        }
                        key={`point-${item}`}>
                        {(!editable && item) ||
                          (marked && marked > index && editable && (
                            <CupSVG className='absolute bottom-0.5 h-6 w-6' />
                          )) || (
                            <FaPlus
                              className='fill-stroke-gray-500'
                              width={20}
                            />
                          )}
                      </div>
                    ))}
                  </div>
                  {points.length > 0 && (
                    <div>
                      <button
                        disabled={(marked && offers && offers?.cost > marked) || false}
                        onClick={() =>
                          offers ?
                            onClaimClicked?.(offers.label, offers.cost)
                            : undefined
                        }
                        className={clsx(
                          `flex h-16 w-16 items-center justify-center rounded-full border-2 bg-white transition-colors
                        duration-300`,
                          {
                            'border-light-secondary': !secondaryColor,
                            'pointer-events-none': (marked && offers && offers?.cost > marked) || !offers,
                          },
                        )}
                        style={secondaryColor ? { borderColor: secondaryColor } : undefined}>
                        {!isCreate && (
                          <span className='text-sm font-medium uppercase'>
                            {marked == points.length ? 'Claim' : 'Free'}
                          </span>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              )}
            <div className='flex justify-end'>
              <LogoWhite
                height={24}
                width={100}
                className='invert(72%) sepia(59%) saturate(5613%) hue-rotate(332deg) brightness(160%) contrast(90%)'
              />
            </div>
          </div>
        </TwCardHome>
      </div>
    </Transition>
  )
}

export default Card
