import { IProgramBody } from 'types/program.module'
import { create } from 'zustand'

interface IProgramStore {
  programBody: IProgramBody
  setProgramBody: (event: IProgramBody) => void
}

const initialProgramBody: IProgramBody = {
  name: 'Create your rewards program today',
  description: '',
  location: [],
  primaryColor: '',
  secondaryColor: '',
  logo: undefined,
  reward: 10,
}

const useProgramStore = create<IProgramStore>((set) => ({
  programBody: initialProgramBody,
  setProgramBody: (event: IProgramBody) => set({ programBody: event }),
}))

export { useProgramStore }
