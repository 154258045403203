/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactTagManager from 'react-ga4'
import { FocusSpan, showDialog } from './Dialog'

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>

  prompt(): Promise<{ outcome: 'accepted' | 'dismissed' }>
}

const INSTALL_DIALOG_SHOWN = 'install_dialog_shown'
export type AppDisplayMode = 'browser_tab' | 'standalone'
export type AppInstallationState = 'Installed' | 'Installing' | 'Installed_In_Session'
export interface PwaInstaller {
  installPwa: (() => void) | undefined
  appDisplayMode: AppDisplayMode | undefined
  appInstallState: AppInstallationState | undefined
}

export function usePwaInstallDialog(
  localStorage: Storage,
  appName: string,
  message: (appName: string, Red: FocusSpan) => ReactNode = (appName, FocusSpan) => (
    <>
      <FocusSpan>Get the {appName}</FocusSpan> for Instant Access!
      <br />
      <br />
      You&apos;ll be notified with Badge Notifications
    </>
  ),
): PwaInstaller {
  const [installPromptRequestedBySystem, systemInstallPrompt, appDisplayMode, installed] =
    useSystemPwaPrompt()
  const [installing, setInstalling] = useState(false)
  const showInstallDialogNow = useMemo(() => {
    return installPromptRequestedBySystem && appDisplayMode !== 'standalone' ?
        () => {
          showInstallAppDialog(
            (FocusSpan) => message(appName, FocusSpan),
            systemInstallPrompt,
            (installing) => setInstalling(installing),
          )
          localStorage.setItem(INSTALL_DIALOG_SHOWN, Date.now().toString())
        }
      : undefined
  }, [
    systemInstallPrompt,
    localStorage,
    installPromptRequestedBySystem,
    appDisplayMode,
    appName,
    message,
  ])
  const installState: AppInstallationState | undefined = useMemo(() => {
    if (installed && installing) return 'Installed_In_Session'
    if (installed) return 'Installed'
    if (installing) return 'Installing'
    return undefined
  }, [installing, installed])
  // useEffect(() => {
  //   const lastSeen = localStorage.getItem(INSTALL_DIALOG_SHOWN)
  //   const lastSeenTime = lastSeen ? parseInt(lastSeen) : 0
  //   const restPeriod = 1000 * 60 * 60 * 24 * 30 //30days
  //   const seenBeforeRestPeriod = lastSeenTime < Date.now() - restPeriod
  //   if (
  //     installPromptRequestedBySystem &&
  //     documentPermissions === 'edit' &&
  //     seenBeforeRestPeriod &&
  //     appDisplayMode !== 'standalone'
  //   ) {
  //     showInstallDialogNow?.()
  //   }
  // }, [
  //   installPromptRequestedBySystem,
  //   documentPermissions,
  //   systemInstallPrompt,
  //   localStorage,
  //   showInstallDialogNow,
  //   appDisplayMode,
  // ])

  return useMemo(
    () => ({ installPwa: showInstallDialogNow, appDisplayMode, appInstallState: installState }),
    [showInstallDialogNow, appDisplayMode, installState],
  )
}

const WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY = 'WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY'
type BeforeInstallEventHolder = { event: BeforeInstallPromptEvent | undefined }

// @ts-ignore
if (!window[WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY]) {
  // @ts-ignore
  window[WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY] = {
    event: undefined,
  } satisfies BeforeInstallEventHolder
}
export function useSystemPwaPrompt(): [
  promptEvent: BeforeInstallPromptEvent | null,
  prompt: () => Promise<boolean>,
  appDisplayMode: AppDisplayMode | undefined,
  installedThisSession: boolean,
] {
  const { current: promptEventHolder } = useRef<BeforeInstallEventHolder>(
    // @ts-ignore
    window[WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY],
  )
  const [appDisplayMode, setAppDisplayMode] = useState<AppDisplayMode>()
  const [installed, setInstalled] = useState<boolean>(false)

  const promptToInstall = useCallback(async () => {
    if (promptEventHolder.event) {
      const result = await promptEventHolder.event.prompt()
      return result.outcome === 'accepted'
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event'),
    )
  }, [promptEventHolder])
  useEffect(() => {
    const ready = () => {
      setTimeout(() => {
        setInstalled(true)
      }, 10 * 1000)
      ReactTagManager.event('install_dialog_installing_started', { category: 'app_install' })
    }
    window.addEventListener('appinstalled', ready)
    return () => {
      window.removeEventListener('appinstalled', ready)
    }
  }, [])
  useEffect(() => {
    const ready = (e: BeforeInstallPromptEvent) => {
      // @ts-ignore
      window[WINDOW_BEFORE_INSTALL_EVENT_HOLDER_KEY].event = e
    }

    window.addEventListener('beforeinstallprompt', ready as any)
    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any)
    }
  }, [])
  useEffect(() => {
    const checkDisplayMode = () => {
      let displayMode: AppDisplayMode = 'browser_tab'
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone'
      }

      setAppDisplayMode(displayMode)
    }
    checkDisplayMode()
    window.addEventListener('DOMContentLoaded', checkDisplayMode as any)

    return () => {
      window.removeEventListener('DOMContentLoaded', checkDisplayMode as any)
    }
  }, [])

  return [promptEventHolder.event ?? null, promptToInstall, appDisplayMode, installed]
}

export function showInstallAppDialog(
  message: (Red: FocusSpan) => ReactNode,
  systemPrompt: () => Promise<boolean>,
  onDismissed: (installing: boolean) => void,
) {
  ReactTagManager.event('install_dialog_shown', { category: 'app_install' })
  showDialog({
    title: 'App Launcher',
    children: message,
    positiveButtonProps: {
      text: 'Install',
      onClicked: async () => {
        ReactTagManager.event('install_dialog_install_clicked', { category: 'app_install' })
        const result = await systemPrompt()
        onDismissed(result)
        return true
      },
    },
    negativeButtonProps: {
      text: 'Maybe later',
      onClicked: () => {
        ReactTagManager.event('install_dialog_deny_clicked', { category: 'app_install' })
        onDismissed(false)
        return true
      },
    },
    user_dismissable: true,
  })
}
