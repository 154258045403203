const CardSkeleton: React.FC = () => {
  return (
    <div className='max-w-sm space-y-4 rounded-lg bg-white p-4 shadow-md'>
      <div className='mb-4 flex items-center justify-between'>
        <div className='skeleton h-6 w-1/3 animate-pulse rounded bg-gray-300'></div>
        <div className='skeleton h-6 w-16 animate-pulse rounded bg-gray-300'></div>
      </div>
      <div className='skeleton mb-4 h-40 w-full animate-pulse rounded bg-gray-300'></div>
      <div className='space-y-2'>
        <div className='skeleton h-4 w-3/4 animate-pulse rounded bg-gray-300'></div>
        <div className='skeleton h-4 w-1/2 animate-pulse rounded bg-gray-300'></div>
        <div className='mt-4 flex space-x-2'>
          {[...Array(10)].map((_, index) => (
            <div
              key={index}
              className='skeleton h-8 w-8 animate-pulse rounded-full bg-gray-300'></div>
          ))}
        </div>
        <div className='mt-4 flex items-center justify-between'>
          <div className='skeleton h-8 w-16 animate-pulse rounded-full bg-gray-300'></div>
          <div className='skeleton h-4 w-1/4 animate-pulse rounded bg-gray-300'></div>
        </div>
      </div>
    </div>
  )
}

export default CardSkeleton
