import DocumentTitle from 'react-document-title'
import MerchantAfterScan from './components/MerchantAfterScan'

const MerchantPage: React.FC = () => {
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <MerchantAfterScan />
    </DocumentTitle>
  )
}

export default MerchantPage
