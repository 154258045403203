import * as React from 'react'
import { MutableRefObject, PropsWithChildren, useCallback, useRef } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { compositeRefObject } from './CompositeRefObject'

export function ScrollBox({
  innerRef,
  children,
  direction,
  ...boxProps
}: PropsWithChildren &
  BoxProps & {
    direction: 'horizontal' | 'vertical'
    innerRef?: MutableRefObject<HTMLDivElement | undefined>
  }) {
  const ref = useRef<HTMLDivElement>()
  const handleWheel = useCallback(
    (event: React.WheelEvent) => {
      if (ref.current) {
        if (direction === 'horizontal') {
          // noinspection JSSuspiciousNameCombination
          ref.current?.scrollBy({ left: event.deltaY })
          event.stopPropagation()
        }
      }
    },
    [direction],
  )
  return (
    <Box
      ref={compositeRefObject(ref, innerRef)}
      overflowY={direction === 'vertical' ? 'auto' : 'clip'}
      overflowX={direction === 'horizontal' ? 'auto' : 'clip'}
      onWheel={handleWheel}
      {...boxProps}>
      {children}
    </Box>
  )
}
