import { Image } from '@chakra-ui/react'
import { TwCardContainer } from 'components/modules/twComponent/twCard.module'
import { ICard } from 'types/card.module'

const MerchantCard: React.FC<{ merchantConfig: ICard; onClick: () => void }> = ({
  merchantConfig,
  onClick,
}) => {
  return (
    <TwCardContainer
      onClick={onClick}
      style={{ background: `${merchantConfig.primaryColor}` }}
      className='min-h-36 w-36 min-w-36 border'>
      <div className='h-18'>
        {merchantConfig.logo && (
          <Image
            src={merchantConfig.logo}
            objectFit={'contain'}
            height={'100%'}
          />
        )}
      </div>

      <span className='line-clamp-2 text-csm font-medium'>{merchantConfig.name}</span>
    </TwCardContainer>
  )
}
export default MerchantCard
