import clsx from 'clsx'
import { cn } from 'components/common/utils/tailwindUtils'
import { TwLableForm } from 'components/modules/twComponent/twLayout.module'
import { useMemo } from 'react'
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'
import { ClassNameValue } from 'tailwind-merge'
import tw from 'twin.macro'

interface IInputFieldProps<T extends FieldValues> {
  register: UseFormRegister<T>
  name: Path<T>
  errors?: string
  type?: string
  label?: string
  classNameLabel?: string
  typeLable?: 'center' | 'left'
  placeholder?: string
  isRequired?: boolean
  defaultValue?: string
  disabled?: boolean
  validation?: RegisterOptions<T>
  onChange?: (event: any) => void
  multiple?: boolean
  row?: number
  className?: ClassNameValue
  [other: string]: any
}

const InputField = <T extends FieldValues>({
  register,
  name,
  errors,
  type,
  label,
  classNameLabel,
  typeLable = 'left',
  placeholder,
  validation,
  onChange,
  multiple,
  className,
  row = 3,
  defaultValue,
  ...props
}: IInputFieldProps<T>) => {
  const renderLabelStyle = useMemo(() => {
    return {
      center: 'text-center tracking-widest text-xl text-light-secondary',
      left: 'text-left text-black',
    }[typeLable]
  }, [typeLable])

  return (
    <div className='mb-2 w-full'>
      <TwLableForm
        htmlFor={name}
        className={cn(classNameLabel, renderLabelStyle)}>
        {label}
      </TwLableForm>
      {(multiple && (
        <textarea
          {...props}
          {...register(name, validation)}
          placeholder={placeholder}
          className={clsx(
            `w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out
            focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm`,
            {
              'border-red-500': errors,
            },
          )}
          rows={row}
        />
      )) || (
          <TwInput
            {...props}
            {...register(name, validation)}
            type={type}
            placeholder={placeholder}
            className={clsx(className, errors ? 'border-red-500' : 'border-gray-300')}
          />
        )}
      {errors && <TwErrorForm>{errors}</TwErrorForm>}
    </div>
  )
}
export default InputField

export const TwErrorForm = tw.p`text-xs italic text-red-500 transition duration-150 ease-in-out`
export const TwInput = tw.input` w-full appearance-none rounded-full border px-3 py-2 leading-tight text-gray-700 focus:outline-none transition duration-150 ease-in-out bg-gray-100 border-gray-100 focus:bg-gray-100 focus:border-gray-200 focus:shadow-sm`
export const TwButton = tw.button`w-full bg-light-primary text-white py-2 rounded-md font-semibold hover:brightness-110`
