import { TwLableForm } from 'components/modules/twComponent/twLayout.module'
import { useCallback } from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form'
import SliderInput from './SliderInput'
import UploadInput from './UploadInput'

export interface CustomInputProps<T extends FieldValues> {
  label: string
  name: Path<T>
  type: 'text' | 'number' | 'textarea' | 'file' | 'range'
  control: Control<any>
  defaultValue?: any
  rules?: RegisterOptions<T>
  error?: FieldError
  clearErrors?: (name: Path<T>) => void
  onChangeCallback?: (value: any) => void
  setError?: (name: Path<T>, error: any) => void
  [x: string]: any
}

const CustomInput = <T extends FieldValues>({
  label,
  name,
  type,
  control,
  defaultValue = '',
  rules = {},
  error,
  clearErrors,
  setError,
  onChangeCallback,
  ...rest
}: CustomInputProps<T>) => {
  const renderInput = useCallback(
    (field: any) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onChangeCallback) {
          const value = (type === 'file' && e) || (type === 'range' && e) || e.target.value
          onChangeCallback(value)
          field.onChange(e)
        }
      }
      switch (type) {
        case 'textarea':
          return (
            <textarea
              id={name}
              name={name}
              className={`w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm transition duration-150
                ease-in-out focus:border-blue-500 focus:outline-none focus:ring-blue-500
                ${error ? 'border-red-500' : ''}`}
              {...field}
              onChange={handleChange}
              value={field.value || ''}
              {...rest}
            />
          )
        case 'file':
          return (
            <UploadInput
              name={name}
              error={error}
              {...field}
              clearErrors={clearErrors}
              onChange={handleChange}
              setError={setError}
              value={field.value}
              {...rest}
            />
          )
        case 'range':
          return (
            <SliderInput
              name={name}
              error={error}
              {...field}
              value={field.value || ''}
              onChange={handleChange}
              {...rest}
            />
          )
        default:
          return (
            <input
              id={name}
              name={name}
              type={type}
              className={`w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-gray-700 shadow
                focus:outline-none ${error ? 'border-red-500' : ''}`}
              {...field}
              value={field.value || ''}
              onChange={handleChange}
              {...rest}
            />
          )
      }
    },
    [type, onChangeCallback, name, error, rest, clearErrors, setError],
  )

  return (
    <div className='mb-4 w-full'>
      <TwLableForm
        className='mb-2 block font-bold text-gray-700'
        htmlFor={name}>
        {label}
      </TwLableForm>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='flex flex-col'>
              {renderInput(field)}
              <div className='h-4'>
                {error && <p className='text-xs italic text-red-500'>{error.message}</p>}
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default CustomInput
