import { useMerchantPrivateStore } from 'UseMerchantStore'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import { MerchantRecentEvent } from '../../functions/src/data/common'

interface MerchantNotificationsPanelProps {
  merchantId: string | undefined
  firebaseDb: FirebaseDb
  user: User
}

interface UseNotificationMerchantResult {
  data: MerchantRecentEvent[] | boolean
  type: string
}

const useNotificationMerchant = ({
  merchantId,
  firebaseDb,
  user,
}: MerchantNotificationsPanelProps): UseNotificationMerchantResult => {
  const merchantPrivateStoreResult = useMerchantPrivateStore(merchantId, user, firebaseDb)
  const merchantAdminPermissions = merchantPrivateStoreResult.type

  const notificaions =
    merchantAdminPermissions === 'SUCCESS' &&
    Object.values(merchantPrivateStoreResult.merchantData.recentEvents ?? {})
      ?.orderByDesc((it) => it.timestamp)
      .slice(0, 10)

  //todo handle more notifications for customer
  return {
    data: notificaions,
    type: merchantAdminPermissions,
  }
}

export default useNotificationMerchant
