import DocumentTitle from 'react-document-title'
import Dashboard from './components/Dashboard'

const HomePage: React.FC = () => {
  return (
    <DocumentTitle title={'Instant Loyalty - Home'}>
      <Dashboard />
    </DocumentTitle>
  )
}

export default HomePage
