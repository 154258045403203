import { BackgroundIcon } from '../components/BackgroundIcon'

export type ActionProps =
  | {
      // Sign in to ___
      title: string
      // <Red>You'll need to sign in first</Red> to ___
      message?: string
    }
  | {
      // Sign in to ___
      title: string
      // <Red>You'll need to sign in first</Red> to ___
      message: string
    }
  | {
      // Sign in to ___
      title?: string
      // <Red>You'll need to sign in first</Red> to ___
      message: string
    }
interface PillContainerProps {
  pills: PillProps[]
}

interface PillProps {
  icon?: string
  title?: string
  selectedColor?: string
  selected?: boolean
  onClick?: () => void
}

export function PillContainer(props: PillContainerProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
      }}>
      {props.pills.map((pill, index) => (
        <Pill
          key={index}
          {...pill}
        />
      ))}
    </div>
  )
}

export function Pill({
  selected = false,
  selectedColor = '#DD3D4E',
  title,
  onClick,
  icon,
}: PillProps) {
  return (
    <div
      style={{
        backgroundColor: (selected && selectedColor) || '#353A44',
        padding: '8px 12px',
        position: 'relative',
        display: 'inline-flex',
        borderRadius: '999px',
        fontWeight: 550,
        fontSize: '1em',
        verticalAlign: 'middle',
        fontFamily: 'LeagueSpartan, sans-serif',
        color: 'white',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={onClick}>
      {icon && (
        <BackgroundIcon
          src={icon}
          style={{ display: 'inline-block', height: '1em' }}
        />
      )}
      <span
        style={{
          background: 'inherit',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          filter: 'invert() brightness(2) grayscale(1) contrast(30)',
        }}>
        {title ?? undefined}
      </span>
    </div>
  )
}
