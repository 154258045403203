import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { RgbaColorPicker } from 'react-colorful'

interface IColorRGB {
  r: number
  g: number
  b: number
  a: number
}

interface IColorPicker {
  value?: string
  onChange: (value: string) => void
}

const ColorPickerComponent = forwardRef<HTMLDivElement, IColorPicker>(({ onChange }, ref) => {
  const [color, setColor] = useState<IColorRGB>({ r: 0, g: 0, b: 0, a: 1 })
  const [hex, setHex] = useState<string>('#000000')

  const handleOnchange = (e: IColorRGB) => {
    setColor(e)
    setHex(rgbToHex(e))
    onChange && onChange(rgbToHex(e))
  }

  const handleRgbChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      const newColor = { ...color, [name]: Number(value) }
      setColor(newColor)
      setHex(rgbToHex(newColor))
      onChange && onChange(rgbToHex(newColor))
    },
    [color, onChange],
  )

  const handleHexChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const hexValue = e.target.value
      setHex(hexValue)
      onChange && onChange(hexValue)
      const rgbColor = hexToRgb(hexValue)
      if (rgbColor) {
        setColor({ ...rgbColor, a: color.a })
      }
    },
    [color.a, onChange],
  )

  const rgbToHex = ({ r, g, b, a }: IColorRGB): string => {
    const toHex = (value: number) => value.toString(16).padStart(2, '0').toUpperCase()

    const hexR = toHex(r)
    const hexG = toHex(g)
    const hexB = toHex(b)
    const hexA = a !== undefined ? toHex(Math.round(a * 255)) : '' // Handle alpha if provided

    return `#${hexR}${hexG}${hexB}${hexA}`
  }

  const hexToRgb = (hex: string) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b)
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ?
        {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  const colorStyle = useMemo(
    () => ({
      backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    }),
    [color],
  )

  return (
    <div
      className='rounded-lg bg-gray-800 text-white shadow-lg'
      ref={ref}>
      <RgbaColorPicker
        color={color}
        onChange={handleOnchange}
        className='mb-4 !w-full'
      />

      <div className='flex items-start justify-center gap-x-2 p-2'>
        <div
          className='h-8 w-8 whitespace-nowrap rounded-full'
          style={colorStyle}></div>
        <div className='flex items-center gap-2'>
          <input
            type='text'
            value={hex}
            onChange={handleHexChange}
            className='rounded border bg-transparent p-1 text-center text-sm'
          />
          <label className='text-csm font-medium'>HEX</label>
        </div>
      </div>
      <div className='mb-4 flex items-start justify-center gap-x-4 p-2'>
        <div className='flex items-center gap-x-2'>
          <div className='flex flex-col items-center justify-center gap-y-1'>
            <input
              name='r'
              value={color.r}
              onChange={handleRgbChange}
              className='w-12 rounded border bg-transparent p-1 text-center text-sm'
            />
            <label className='text-csm font-medium'>R</label>
          </div>
          <div className='flex flex-col items-center justify-center gap-y-1'>
            <input
              name='g'
              value={color.g}
              onChange={handleRgbChange}
              className='w-12 rounded border bg-transparent p-1 text-center text-sm'
            />
            <label className='text-csm font-medium'>G</label>
          </div>
          <div className='flex flex-col items-center justify-center gap-y-1'>
            <input
              name='b'
              value={color.b}
              onChange={handleRgbChange}
              className='w-12 rounded border bg-transparent p-1 text-center text-sm'
            />
            <label className='text-csm font-medium'>B</label>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center gap-y-1'>
          <input
            name='b'
            value={color.a}
            onChange={handleRgbChange}
            className='w-12 rounded border bg-transparent p-1 text-center text-sm'
          />
          <label className='text-csm font-medium'>A</label>
        </div>
      </div>
    </div>
  )
})

ColorPickerComponent.displayName = 'ColorPickerComponent'
export default ColorPickerComponent
