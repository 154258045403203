import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import clsx from 'clsx'
import { ButtonIcon, TwButton } from 'components/button/ButtonIcon'
import { initFirebase, useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import CustomInput from 'components/input/CustomInput'
import { TwLableForm, TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { PATH } from 'constants/routes'
import { getDatabase, ref, set } from 'firebase/database'
import { useMerchantData } from 'hooks/useMerchantData'
import { ReactComponent as LocationSVG } from 'icons/location.svg'
import { ReactComponent as TrashSVG } from 'icons/trash.svg'
import ProgramCard from 'pages/Home/components/ProgramCard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useProgramStore } from 'stores/programStore'
import { IAddress } from 'types/program.module'
import NotificationBox from 'ui/NotificationBox'
import { MerchantEntry, TokenCardConfig } from '../../../functions/src/data/common'
import ColorPickerPopover from './components/ColorPickerPopover'
import { useMerchantCreateStore, useUserTeamsStore } from 'UseMerchantStore'
import { showDialog } from 'components/common/Dialog'

export interface IProgramForm extends FieldValues {
  name: string
  description?: string
  location: string
  primaryColor: string
  secondaryColor: string
  logo?: FileList
  reward: number
  isTest?: boolean
}

const ProgramCreate: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
    resetField,
    reset,
  } = useForm<IProgramForm>()

  const [address, setAddress] = useState<IAddress[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [initialResetDone, setInitialResetDone] = useState<boolean>(false)
  const [trialValue, setTrialValue] = useState<number>(0)
  const [logoEdit, setLogoEdit] = useState<string | null>(null) // Provide a default value of an empty string
  const [clearImgToData, setClearImgToData] = useState<boolean>(false)

  const firebase = useFirebase(firebaseConfig)
  const { setProgramBody, programBody } = useProgramStore()
  const {  isCreating } = useMerchantCreateStore(firebase.firebaseDb)
  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)

  const merchantId = userTeams.teams && userTeams.teams.permissions ? Object.keys(userTeams.teams.permissions)[0] : ''

  const merchantData = useMerchantData(id ?? merchantId ?? '')

  const handleSubmitTest = async () => {
    setValue('isTest', true)
    await onSubmit()
  }

  const onSubmit = handleSubmit(async (value) => {
    if (address.length === 0) {
      setError('location', { type: 'value', message: 'Store location must be added' })
      return
    }
    const newProgram: MerchantEntry = {
      name: merchantData?.merchant?.name || '',
      logo: programBody.logo || logoEdit,
      defaultCardConfigId: 'token',
      cardConfigs: {
        token: {
          cardConfigType: 'tokens',
          locations: address,
          isLaunched: !value.isTest,
          templates: {
            default: {
              templateType: 'tokens',
              offers: [{ cost: 9, label: 'coffee' }],
              trial: isEdit ? trialValue : 0,
              target: value.isTest ? 3 : programBody.reward,
              lineMax: value.reward || programBody.reward,
              primaryColor: value.primaryColor || programBody.primaryColor,
              secondaryColor: value.secondaryColor || programBody.secondaryColor,
            },
          },
          defaultTemplateId: 'default',
          description: value.description || programBody.description || '',
          logo: programBody.logo || logoEdit,
          title: value.name || programBody.name,
        } satisfies TokenCardConfig,
      }

    }

    if (id || user?.uid) {
      const { app } = initFirebase(firebaseConfig)

      const db = getDatabase(app)
      const newDocRef = ref(db, `/merchants/${id || user?.uid}`)
      set(newDocRef, {
        ...newProgram,
      })
        .then(() => {
          toast('Create the program successfully!')
          navigate(PATH.HOME)
        })
        .catch((error) => {
          console.error('error merchant save', error)
        })
    } else {
      console.error('Not found uid')
    }
  })

  const handleInputChange = (name: keyof IProgramForm, value: any) => {
    if (name !== 'location') {
      setProgramBody({ ...programBody, [name]: value })
    }
  }

  const handleClearAddress = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault()
    if (address.length > 0) {
      const newAdress = address.filter((item) => item.id !== id)
      setAddress(newAdress)
    }
  }

  const handleAddAddress = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const newAddress = getValues('location')
      if (address.length >= 3) {
        setIsError(true)
      } else {
        setIsError(false)
      }
      if (newAddress) {
        const lastItem = address[address.length - 1]
        const newId = lastItem ? lastItem.id + 1 : 1
        const mergeAddress = [
          ...address,
          {
            id: newId,
            address: newAddress,
            // isDefault: address.length === 0,
          },
        ]
        setAddress(mergeAddress)
        setProgramBody({ ...programBody, location: mergeAddress })
        clearErrors('location')
        setValue('location', '')
      } else {
        setError('location', { type: 'value', message: 'Store location is empty!' })
      }
    },
    [address, clearErrors, getValues, programBody, setError, setProgramBody, setValue],
  )


  const tokenCardConfig = useMemo(() => {
    if (merchantData?.merchant.cardConfigs) {
      const data = merchantData.merchant

      return Object.values(data.cardConfigs ?? {}).mapNotNull((cardConfig) => (cardConfig.cardConfigType === 'tokens' ? cardConfig : undefined)).firstOrNull()
    }
    return undefined
  }, [merchantData?.merchant])

  useEffect(() => {
    // when the merchant had a program
    if (merchantData?.merchant && !initialResetDone) {
      const template = tokenCardConfig?.templates?.[tokenCardConfig.defaultTemplateId]

      if (!template) {
        return
      }
      reset({
        name: tokenCardConfig.title || undefined,
        reward: template.lineMax as number,
        description: tokenCardConfig.description,
        primaryColor: template.primaryColor,
        secondaryColor: template.secondaryColor,
      })
      setLogoEdit(merchantData.merchant.logo)
      setAddress(tokenCardConfig.locations ?? [])
      setTrialValue(template.trial)
      setInitialResetDone(true)
      if (tokenCardConfig.locations && tokenCardConfig.locations.length > 3) {
        setIsError(true)
      }
    }
  }, [merchantData, reset, initialResetDone, tokenCardConfig])

  useEffect(() => {
    const timeoutComplete = setTimeout(() => {
      if (!user) {
        showDialog({
          title: 'Oops!',
          children: 'You need to log in to register your business',
          positiveButtonProps: {
            text: 'Sign in',
            onClicked: () => {
              navigate(`/signin?redirect=${encodeURIComponent(location.pathname + location.search + location.hash)}`)
              return true
            }
          },
        })
      }
    }, 2000)

    return () => clearTimeout(timeoutComplete)
  }, [navigate, user])

  if (isEdit && !merchantData || !user) {
    return <SkeletonHome />
  }


  return (
    <section className='layout'>
      <TwMainGridWrapper className='h-[100vh] lg:h-[calc(95vh-100px)]'>
        <NotificationBox
          user={user}
          merchantId={id}
          firebaseDb={firebase.firebaseDb}
          className='col-span-12 hidden md:col-span-6 lg:col-span-3 xl:block'
        />
        <ProgramCard
          isCreate={!isEdit}
          isEdit={isEdit}
          className='col-span-12 lg:col-span-4'
          cardConfig={tokenCardConfig}
          merchant={merchantData?.merchant}
        />
        <div
          className='animate-fade-right animate-once animate-ease-in relative col-span-6 box-content space-y-4
            overflow-y-auto rounded bg-white shadow-md lg:col-span-5'>
          <div className='sticky top-0 z-10 flex items-start justify-between bg-white p-4'>
            <div className='space-y-2'>
              <h2 className='animate-fade-right animate-once animate-ease-in text-xl font-semibold'>
                {isEdit ? `Edit your program` : 'Create your program'}
              </h2>
              <p className='text-light-secondary'>
                Fill the below details to create your reward program
              </p>
            </div>
          </div>
          <div className='p-4'>
            <form
              onSubmit={onSubmit}
              className='pb-4'>
              <div className='lg:space-x-6'>
                <div className='col-span-2 space-y-6'>
                  <CustomInput
                    label='Name your program'
                    name='name'
                    type='text'
                    // maxLength={40}
                    error={errors.name}
                    onChangeCallback={(value) => handleInputChange('name', value)}
                    control={control}
                    rules={{ required: 'Name your program is required' }}
                  />

                  <CustomInput
                    label='Choose the number of purchases before your customer gets a reward'
                    name='reward'
                    type='range'
                    defaultValue={10}
                    max={20}
                    error={errors.reward}
                    onChangeCallback={(value) => handleInputChange('reward', value)}
                    control={control}
                    rules={{
                      required: 'Reward is required',
                      min: {
                        value: 1,
                        message: 'Reward must be greater than 0',
                      },
                      max: {
                        value: 20,
                        message: 'Reward must be less 20'
                      }
                    }}
                  />

                  <CustomInput
                    label="Detail your customer's reward"
                    name='description'
                    type='textarea'
                    control={control}
                    rules={{
                      required: "Detail your customer's reward is required",
                    }}
                    error={errors.description}
                    clearErrors={clearErrors}
                    placeholder='Eg. 1x Free Coffee or 20% off next purchase (over $20)'
                    onChangeCallback={(value) => handleInputChange('description', value)}
                    maxLength={50}
                  />

                  <div className='mb-4 space-y-2'>
                    <TwLableForm>Choose card background colour</TwLableForm>
                    <div className='flex gap-x-10'>
                      <ColorPickerPopover
                        control={control}
                        type='text'
                        defaultValue={'bg-slate-300'}
                        name='primaryColor'
                        onChangeCallback={(value) => handleInputChange('primaryColor', value)}
                        label='Background'
                      />
                      <ColorPickerPopover
                        control={control}
                        type='text'
                        defaultValue={'bg-slate-500'}
                        name='secondaryColor'
                        onChangeCallback={(value) => handleInputChange('secondaryColor', value)}
                        label='Secondary'
                      />
                    </div>
                  </div>

                  {(isEdit && !clearImgToData && logoEdit && (
                    <div className='mb-4 flex items-center gap-x-6'>
                      <div className='h-36 w-36'>
                        <img
                          src={logoEdit}
                          alt='Uploaded'
                          className='h-full w-full object-contain'
                        />
                      </div>

                      <button
                        type='button'
                        onClick={() => setClearImgToData(true)}
                        className='focus:shadow-outline rounded-lg bg-gray-300 p-2.5 font-bold text-white hover:bg-gray-500
                          focus:outline-none'>
                        <TrashSVG className='h-5 w-5 fill-white' />
                      </button>
                    </div>
                  )) || (
                      <CustomInput
                        label='Upload your logo'
                        name='logo'
                        type='file'
                        reset={resetField}
                        clearErrors={clearErrors}
                        control={control}
                        setError={setError}
                        placeholder='Drag and drop here'
                        onChangeCallback={(value) => handleInputChange('logo', value)}
                      />
                    )}

                  <div className='relative'>
                    <div className='flex w-full items-center gap-x-2'>
                      <CustomInput
                        label='Store location'
                        name='location'
                        type='text'
                        error={errors.location}
                        onChangeCallback={(value) => handleInputChange('location', value)}
                        control={control}
                        rules={{
                          required: address.length === 0 ? 'Store location is required!' : false,
                        }}
                      />
                      <span className='absolute right-0 top-1 text-xs text-gray-600'>{`${address.length || 0}/3 Included`}</span>
                      <TwButton
                        type='button'
                        onClick={handleAddAddress}
                        className='mb-[1px] h-9 w-9 !border-2 hover:bg-gray-200'>
                        <AddIcon
                          className='!fill-gray-300'
                          color={'GrayText'}
                        />
                      </TwButton>
                    </div>

                    {address.length > 0 && (
                      <ul className='mb-4 space-y-2'>
                        {address.map((item, idx: number) => {
                          return (
                            <li
                              key={`location-${idx}`}
                              className='flex items-start justify-between text-sm'>
                              <span className='flex items-start gap-x-1'>
                                <LocationSVG className='mt-[1px] h-4 w-4 fill-slate-700' />
                                {item.address}
                              </span>
                              <div className='flex items-start gap-x-3'>
                                <TwButton
                                  onClick={(e) => {
                                    handleClearAddress(e, item.id)
                                  }}
                                  type='button'
                                  className='!border-none !p-1 hover:brightness-150'>
                                  <DeleteIcon color={'slategrey'} />
                                </TwButton>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                    {isError && (
                      <div
                        className={clsx('opacity-0 transition-opacity duration-300', {
                          'opacity-100': isError,
                        })}>
                        <span className='text-csm font-medium italic text-orange-500'>
                          Need to add more locations? No problem! You get 1 location and each
                          additional location is just $49
                        </span>
                      </div>
                    )}
                  </div>
                  <TwLableForm>Review and confirm</TwLableForm>

                  <div className='flex justify-start gap-x-4'>
                    <ButtonIcon
                      type='button'
                      disabled={isCreating}
                      onClick={handleSubmitTest}
                      content={isEdit ? 'Update my Card' : 'Test my Card'}
                      className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary
                        hover:text-white'
                    />
                    <ButtonIcon
                      type='button'
                      // onClick={async () => {
                      //   setValue('isTest', false)
                      //   await onSubmit()
                      // }}
                      content='Launch'
                      className='min-w-28 bg-light-primary text-white hover:brightness-110'
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </TwMainGridWrapper>
    </section>
  )
}

export default ProgramCreate
