import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import DocumentTitle from 'react-document-title'
import { useNavigate } from 'react-router-dom'
import './App.css'
import { CustomerQRCard } from './CustomerQRCard'
import { MerchantDetailsCard } from './MerchantPage'
import { useMerchantStore, useUserTeamsStore } from './UseMerchantStore'
import { useUserCardsStore } from './UseUserCardsStore'
import { DraggableScrollBox } from './components/DraggableScrollBox'
import { FirebaseDb, useFirebase } from './components/common/Firebase'
import { RoundButton } from './components/common/RoundButton'
import { useUserNullable } from './components/common/UseUser'
import { firebaseConfig } from './services/FirebaseConfig'

const LAST_URL_KEY = 'last-url'

export function Home() {
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user } = useUserNullable({ auth: loyaltyAuth })
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const state = useUserCardsStore(user, firebaseDb)
  const [showPersonalQR, setShowPersonalQR] = useState(false)
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <DraggableScrollBox
        direction={'vertical'}
        width={'100%'}
        height={'100%'}>
        <Flex
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          minHeight={'100%'}
          gap={20}>
          {userTeams.teams && (
            <DraggableScrollBox
              direction={'horizontal'}
              backgroundColor={'white'}>
              {Object.entries(userTeams.teams.permissions).map(
                ([merchantId, member]) =>
                  member?.permission === 'owner' && (
                    <MerchantStoreMemberLink
                      key={merchantId}
                      merchantId={merchantId}
                      firebaseDb={firebaseDb}
                    />
                  ),
              )}
            </DraggableScrollBox>
          )}
          {user?.uid && showPersonalQR && (
            <CustomerQRCard
              customerId={user.uid}
              firebaseDb={firebaseDb}
              onFlipClicked={() => setShowPersonalQR(false)}
            />
          )}
          {user?.uid && !showPersonalQR && (
            <RoundButton
              onClick={() => setShowPersonalQR(true)}
              alt={'show personal qr code'}>
              Show Personal QR Code
            </RoundButton>
          )}
          {state.type === 'SUCCESS' && !!Object.entries(state.cardsData).length && (
            <DraggableScrollBox direction={'horizontal'}>
              <Flex direction={'row'}>
                {Object.entries(state.cardsData).map(([merchantId, cardsByConfig]) => (
                  <MerchantStoreCustomerLink
                    key={merchantId}
                    merchantId={merchantId}
                    firebaseDb={firebaseDb}
                  />
                ))}
              </Flex>
            </DraggableScrollBox>
          )}
          {state.type === 'NO_CARD' && (
            <div style={{ textAlign: 'center' }}>
              Loading Loyalty programs in your area...
              <br />
              <br />
              Scan a Loyalty QR to get started
            </div>
          )}
        </Flex>
      </DraggableScrollBox>
    </DocumentTitle>
  )
}

export const MerchantStoreMemberLink = (props: { merchantId: string; firebaseDb: FirebaseDb }) => {
  const merchantState = useMerchantStore(props.merchantId, props.firebaseDb)
  const navigate = useNavigate()
  return (
    <Flex
      direction={'column'}
      alignItems={'center'}
      gap={8}
      style={{ borderRadius: 16, padding: 16 }}>
      {merchantState.type === 'SUCCESS' && (
        <>
          Looks like you are the admin of {merchantState.merchantData.name}
          <MerchantDetailsCard
            merchantEntry={merchantState.merchantData}
            style={{ alignItems: 'center' }}
          />
          <RoundButton
            alt={'Take me to the store page'}
            color={'white'}
            backgroundColor={'black'}
            onClick={() => {
              navigate(`/merchant/${props.merchantId}`)
            }}>
            Take me to the store page
          </RoundButton>
        </>
      )}
    </Flex>
  )
}

export const MerchantStoreCustomerLink = (props: {
  merchantId: string
  firebaseDb: FirebaseDb
}) => {
  const merchantState = useMerchantStore(props.merchantId, props.firebaseDb)
  const navigate = useNavigate()
  return (
    <Flex
      direction={'column'}
      alignItems={'center'}
      gap={8}
      style={{ borderRadius: 16, padding: 16 }}>
      {merchantState.type === 'SUCCESS' && (
        <>
          <div
            style={{ width: 'fit-content', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/merchant/${props.merchantId}`)
            }}>
            <MerchantDetailsCard
              merchantEntry={merchantState.merchantData}
              style={{ alignItems: 'center', width: 'fit-content' }}
            />
          </div>
        </>
      )}
    </Flex>
  )
}
