// import ProtectedRoute from 'components/ProtectedRoute'
import ProtectedRoute from 'components/ProtectedRoute'
import { PATH } from 'constants/routes'
import HomePage from 'pages/Home'
import MerchantPage from 'pages/Merchant'
import ProgramCreate from 'pages/Program/ProgramCreate'
import Login from 'pages/SignIn'
import { CardPage } from 'pages/card/CardPage'
import { Route } from 'react-router-dom'
// import layouts
// import WelcomeView from './ui/welcome';
import { CustomerPage } from './CustomerPage'
import { Home } from './Home'
import { MerchantPage as MerchantTest } from './MerchantPage'
import { MerchantTeamInvitePage } from './MerchantTeamInvitePage'

// export const routes = (
//   <Route path="/" element={<MetaLayout />}>
//     <Route path="/" element={<WelcomeView />} />
//     <Route path="editor" element={<PlaybackView />}></Route>
//     <Route path="signin" element={<SignInView />} />
//     <Route path="*" element={<Navigate to="home" />} />
//   </Route>
// );

export const routes = (
  <>
    <Route
      path={PATH.HOME}
      element={<ProtectedRoute component={HomePage} />}
    />
    <Route
      path={'/home'}
      element={<Home />}
    />
    <Route
      path={PATH.SIGN_IN}
      element={<Login />}
    />
    <Route
      path={PATH.PROGRAM_CREATE}
      element={<ProgramCreate />}
    />
    <Route
      path={PATH.PROGRAM_EDIT}
      element={<ProgramCreate isEdit />}
    />
    <Route
      path='/card/:id'
      element={<CardPage />}
    />
    <Route
      path='/customer/:customerId'
      element={<CustomerPage />}
    />
    <Route
      path='/merchant/:id/:configId'
      element={<MerchantTest />}
    />
    <Route
      path='/merchant/:id'
      element={<MerchantPage />}
    />
    <Route
      path={'/teaminvite/merchant/:id/:token'}
      element={<MerchantTeamInvitePage />}
    />
    {/* <Route
      path='/signin'
      element={<SignInPage />}
    /> */}
  </>
)
