import { Box, Flex, Image } from '@chakra-ui/react'
import QRCode from 'react-qr-code'
import React from 'react'
import { RoundButton } from './components/common/RoundButton'

import { CardContainer } from './components/CardContainer'

export function BrandedQRCard(props: {
  qrUrl: string
  logo: string | undefined
  fontFamily: string
  cardTitle: string | undefined
  theme: { backgroundColor: string }
  onFlipClicked?: () => void
}) {
  return (
    <CardContainer
      style={{
        boxShadow: '0 0 20px 20px rgb(255,255,255,0.5), 0 0 20px 10px rgb(0,0,0,0.3)',
        backgroundColor: props.theme.backgroundColor,
      }}
      fontFamily={props.fontFamily}>
      <Flex
        justifyContent={'space-between'}
        flexShrink={1}
        flexGrow={1}
        flexBasis={'auto'}
        height={'20%'}>
        {props.logo && (
          <Image
            src={props.logo}
            objectFit={'contain'}
            height={'100%'}
          />
        )}
        {props.onFlipClicked && (
          <RoundButton
            alt={'barcode'}
            onClick={props.onFlipClicked}>
            Flip
          </RoundButton>
        )}
      </Flex>
      <Flex
        flexGrow={1}
        flexShrink={1}
        flexBasis={'auto'}
        position={'relative'}>
        <span style={{ fontSize: '100%' }}>{props.cardTitle}</span>
      </Flex>
      <Box
        flexShrink={1}
        flexGrow={1}
        flexBasis={'auto'}
        height={'70%'}
        aspectRatio={1 / 1}
        padding={4}
        boxSizing={'border-box'}
        width={'fit-content'}
        backgroundColor={'white'}>
        <QRCode
          value={props.qrUrl}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
    </CardContainer>
  )
}
