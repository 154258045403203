import { BoxProps } from '@chakra-ui/react'
import * as React from 'react'
import { PropsWithChildren, useCallback, useRef } from 'react'
import { ScrollBox } from './ScrollBox'

export function DraggableScrollBox({
  children,
  direction,
  ...boxProps
}: PropsWithChildren & BoxProps & { direction: 'horizontal' | 'vertical' }) {
  const ref = useRef<HTMLDivElement>()
  const handleDrag = useCallback(
    (event: React.PointerEvent) => {
      if (
        ref.current &&
        (event.pointerType === 'touch' ||
          (event.pointerType === 'mouse' && (event.buttons & 1) == 1))
      ) {
        if (direction === 'horizontal') {
          ref.current?.scrollBy({ left: -event.movementX })
        } else {
          ref.current?.scrollBy({ top: -event.movementY })
        }
      }
      event.stopPropagation()
    },
    [direction],
  )
  return (
    <ScrollBox
      draggable={false}
      innerRef={ref}
      onPointerMove={handleDrag}
      direction={direction}
      {...boxProps}>
      {children}
    </ScrollBox>
  )
}
