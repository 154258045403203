import React, { ChangeEvent, useEffect, useState } from 'react'

interface SliderProps {
  min?: number
  max?: number
  step?: number
  initialValue?: number
  value?: number
  onChange?: (value: number) => void
}

const Slider: React.FC<SliderProps> = ({
  min = 0,
  max = 100,
  step = 1,
  initialValue = 50,
  onChange,
  value: valueSlider,
}) => {
  const [value, setValue] = useState<number>(() => valueSlider || initialValue)

  useEffect(() => {
    if (valueSlider !== undefined) {
      setValue(valueSlider)
    }
  }, [valueSlider])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    setValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className='my-3 w-full'>
      {/* <div className='mb-2 flex items-center justify-between'>
        <span className='text-sm text-gray-500'>{min}</span>
        <span className='text-sm text-gray-500'>{value}</span>
        <span className='text-sm text-gray-500'>{max}</span>
      </div> */}
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className='h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200'
      />
      <style>{`
        input[type='range']::-webkit-slider-runnable-track {
          border-radius: 4px;
          height: 2px;
        }
        input[type='range']::-webkit-slider-thumb {
          width: 16px;
          height: 16px;
          background: #64748b;
          border-radius: 50%;
          appearance: none;
          cursor: pointer;
          margin-top: -7px;
        }
        input[type='range']::-moz-range-track {
          background: #4a5568;
          border-radius: 4px;
          height: 2px;
        }
        input[type='range']::-moz-range-thumb {
          width: 16px;
          height: 16px;
          background: #64748b;
          border-radius: 50%;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Slider
