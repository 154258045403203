import { FirebaseDb } from './components/common/Firebase'
import { BrandedQRCard } from './BrandedQRCard'
import React from 'react'

export function CustomerQRCard(props: {
  customerId: string
  firebaseDb: FirebaseDb
  onFlipClicked?: () => void
}) {
  return (
    <>
      <BrandedQRCard
        qrUrl={`${window.location.origin}/customer/${props.customerId}`}
        logo={''}
        fontFamily={'LeagueSpartan'}
        cardTitle={'Personal QR Code'}
        theme={{ backgroundColor: 'black' }}
        onFlipClicked={props.onFlipClicked}
      />
    </>
  )
}
