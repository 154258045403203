import { StrictMode } from 'react'
import DocumentTitle from 'react-document-title'
import ReactDOM from 'react-dom/client'
import ReactTagManager from 'react-ga4'
import { BrowserRouter, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { routes } from './appRoutes'
import { DialogServer } from './components/common/Dialog'
import { NotificationRegistrationProvider } from './components/common/NotificationRegistrationProvider'
import './components/common/utils/typescriptUtils'
import './index.css'
import Layout from './layouts'
import reportWebVitals from './reportWebVitals'
import { firebaseConfig } from './services/FirebaseConfig'
import { disableErrorOverlay } from './util/reactutils'

ReactTagManager.initialize([{ trackingId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID! }])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
if (process.env.NODE_ENV === 'development') {
  disableErrorOverlay()
}
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
root.render(
  <StrictMode>
    {/* <ColorModeScript init ialColorMode={theme.config.initialColorMode} /> */}
    {/* <ChakraProvider theme={theme}> */}
    <DocumentTitle title={'Instant Loyalty'}>
      <BrowserRouter>
        <NotificationRegistrationProvider firebaseConfig={firebaseConfig}>
          <Layout>
            <Routes>{routes}</Routes>
          </Layout>
        </NotificationRegistrationProvider>
        <DialogServer
          primaryColor={'#00A79D'}
          textColor={'black'}
          dialogContainerColor={'white'}
        />
        <ToastContainer style={{ zIndex: 100000 }} />
      </BrowserRouter>
    </DocumentTitle>
    {/* </ChakraProvider> */}
  </StrictMode>,
)
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
