import { FirebaseError } from 'firebase/app'
import { User } from 'firebase/auth'
import { useMemo } from 'react'
import { UserCardsCollection } from '../functions/src/data/common'
import { FirebaseDb, useDatabaseNullableRefLiveValue } from './components/common/Firebase'

export type UserCardsState =
  | {
      type: 'SUCCESS'
      cardsData: UserCardsCollection
    }
  | { type: 'FAIL'; error: FirebaseError }
  | { type: 'NO_CARD' }
  | { type: 'PENDING' }

export function useUserCardsStore(
  user: User | undefined | null,
  firebaseDb: FirebaseDb,
): UserCardsState {
  const ref = useMemo(() => {
    return user?.uid ? firebaseDb.getRef(`/users/${user.uid}/merchants`) : undefined
  }, [user, firebaseDb])

  const cardsData = useDatabaseNullableRefLiveValue<UserCardsCollection>({ ref })

  return useMemo(() => {
    if (cardsData instanceof Error) {
      return { type: 'FAIL', error: cardsData as FirebaseError }
    } else if (cardsData === undefined) {
      return { type: 'PENDING' }
    } else if (cardsData === null || Object.keys(cardsData).length === 0) {
      return { type: 'NO_CARD' }
    } else {
      return {
        type: 'SUCCESS',
        cardsData: cardsData,
      }
    }
  }, [cardsData])
}
