import { Flex, IconButton, Image } from '@chakra-ui/react'
import { RoundButton } from 'components/common/RoundButton'
import { User } from 'firebase/auth'
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { FaHome } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { MerchantEntry, PendingScanLog, UserCardsCollection } from '../functions/src/data/common'
import { BrandedQRCard } from './BrandedQRCard'
import { LoyaltyCardSheet } from './LoyaltyCardSheet'
import { OnboardingSheet } from './OnboardingSheet'
import { useMerchantPrivateStore, useMerchantStore } from './UseMerchantStore'
import { CardContainer } from './components/CardContainer'
import { DraggableScrollBox } from './components/DraggableScrollBox'
import { useQueryString } from './components/QueryString'
import {
  FirebaseComponents,
  FirebaseDb,
  WrappedAuth,
  useDatabaseNullableRefLiveValue,
  useFirebase,
} from './components/common/Firebase'
import { pwaContext } from './components/common/NotificationRegistrationProvider'
import { useUserNullable } from './components/common/UseUser'
import { firebaseConfig } from './services/FirebaseConfig'

export function MerchantPage() {
  const { id: merchantId, configId } = useParams()
  const [instorescan] = useQueryString('instorescan', undefined)

  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const merchantPrivateStoreResult = useMerchantPrivateStore(merchantId, user, firebaseDb)
  const merchantAdminPermissions = merchantPrivateStoreResult.type
  const [creating, setCreating] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (instorescan && merchantId && user) {
      firebaseDb
        .getRef('/pendingScanLog')
        .push({ merchantId: merchantId, triggeredByUid: user.uid } satisfies PendingScanLog)
        .then(() => {
          const url = new URL(location.toString())
          url.searchParams.delete('instorescan')
          navigate(url.pathname + url.search, { replace: true })
        })
    }
  }, [instorescan, navigate, merchantId, user, firebaseDb])
  const theme = {
    background: '#DED2C5',
  }
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <Flex
        backgroundColor={theme.background}
        direction={'column'}
        w={'100vw'}
        justifyContent={'flex-start'}>
        <Flex
          direction={'row'}
          justifyContent={'center'}
          flexShrink={0}>
          <IconButton
            onClick={() => navigate('/')}
            border={'none'}
            padding={10}
            borderRadius={5}
            // backgroundColor={'rgb(0,0,0,0)'}
            aria-label={'home'}
            icon={<FaHome />}
          />
          {user !== undefined && <SignIn />}
        </Flex>
        <Flex
          direction={'row'}
          flexShrink={1}
          minHeight={0}>
          {merchantPrivateStoreResult.type === 'SUCCESS' && (
            <DraggableScrollBox
              direction={'vertical'}
              width={'450px'}
              backgroundColor={'rgb(0,0,0,0.2)'}>
              <Flex
                direction={'column'}
                alignItems={'center'}
                padding={50}
                gap={20}>
                <h1 style={{ color: 'wheat' }}>Events</h1>
                {Object.values(merchantPrivateStoreResult.merchantData.recentEvents ?? {})
                  ?.orderByDesc((it) => it.timestamp)
                  .slice(0, 10)
                  .map((it) => (
                    <CardContainer
                      style={{
                        width: 250,
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                      key={it.timestamp}
                      onClick={() => location.assign(it.link)}>
                      <b style={{ whiteSpace: 'pre-wrap', minWidth: 0 }}>{it.title}</b>
                      <span style={{ fontSize: '0.8em' }}>{it.body}</span>
                    </CardContainer>
                  ))}
              </Flex>
            </DraggableScrollBox>
          )}
          <DraggableScrollBox
            direction={'vertical'}
            h={'100vh'}
            w={'100svw'}>
            <Flex
              direction={'column'}
              alignItems={'center'}
              padding={50}
              gap={50}>
              {merchantId && user && merchantAdminPermissions === 'SUCCESS' && configId && (
                <MerchantQRCard
                  configId={configId}
                  merchantId={merchantId}
                  firebase={firebase}
                  user={user}
                  qrUrl={`${window.location.origin}/merchant/${merchantId}?instorescan=true`}
                />
              )}
              {merchantId && user && (
                <LoggedInMerchantOnboardingSheet
                  merchantId={merchantId}
                  configId={configId}
                  firebaseDb={firebaseDb}
                  auth={loyaltyAuth}
                  user={user}
                  creating={creating}
                  onCreating={setCreating}
                />
              )}
              {merchantId && user === null && (
                <LoggedOutMerchantOnboardingSheet
                  merchantId={merchantId}
                  configId={configId}
                  firebaseDb={firebaseDb}
                  auth={loyaltyAuth}
                  user={user}
                  creating={creating}
                  onCreating={setCreating}
                />
              )}
            </Flex>
          </DraggableScrollBox>
        </Flex>
      </Flex>
    </DocumentTitle>
  )
}

export function MerchantDetailsCard(props: {
  merchantEntry: MerchantEntry
  style?: CSSProperties
}) {
  return (
    <CardContainer style={props.style}>
      <Flex
        justifyContent={'space-between'}
        flexShrink={1}
        flexGrow={1}
        flexBasis={'auto'}
        height={'20%'}>
        {props.merchantEntry.logo && (
          <Image
            src={props.merchantEntry.logo}
            objectFit={'contain'}
            height={'100%'}
          />
        )}
      </Flex>
      <Flex
        flexGrow={1}
        flexShrink={1}
        flexBasis={'auto'}
        position={'relative'}>
        <span style={{ fontSize: '100%' }}>{props.merchantEntry.name}</span>
      </Flex>
    </CardContainer>
  )
}

export function MerchantQRCard(props: {
  merchantId: string
  firebase: FirebaseComponents
  qrUrl: string
  onFlipClicked?: () => void
  configId: string
  user: User | undefined | null
}) {
  const merchantState = useMerchantStore(props.merchantId, props.firebase.firebaseDb)
  const { notificationRegisterer, pwaInstaller } = useContext(pwaContext)

  // useEffect(() => {
  //   if (
  //     notificationRegisterer &&
  //     notificationRegisterer.notificationRegistrationState !== 'Not supported' &&
  //     notificationRegisterer.notificationRegistrationState !== 'Ready'
  //   ) {
  //     notificationRegisterer.requestForNotifications()
  //   }
  // }, [notificationRegisterer])
  return (
    <>
      {merchantState.type === 'SUCCESS' && (
        <>
          {notificationRegisterer?.notificationRegistrationState === 'Ready' &&
            `You will receive Loyalty Notifications for this store. 👍`}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            !pwaInstaller?.appInstallState && (
              <RoundButton onClick={pwaInstaller?.installPwa}>
                Install App to get notifications
              </RoundButton>
            )}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            pwaInstaller?.appInstallState === 'Installing' && (
              <RoundButton aria-disabled={true}>Installing...</RoundButton>
            )}
          <BrandedQRCard
            qrUrl={props.qrUrl}
            logo={merchantState.merchantData.logo || undefined}
            fontFamily={'LeagueSpartan'}
            cardTitle={merchantState.merchantData.cardConfigs?.[props.configId]?.title ?? undefined}
            theme={{ backgroundColor: 'black' }}
            onFlipClicked={props.onFlipClicked}
          />
        </>
      )}
    </>
  )
}

function LoggedInMerchantOnboardingSheet(props: {
  user: User
  merchantId: string
  firebaseDb: FirebaseDb
  auth: WrappedAuth
  creating: boolean
  onCreating: (creating: boolean) => void
  configId: string | undefined
}) {
  const userCardStore = useUserCardsStore(props.user, props.firebaseDb)
  const merchantStore = useMerchantStore(props.merchantId, props.firebaseDb)
  const navigate = useNavigate()
  const configIds =
    props.configId ? [props.configId]
    : merchantStore.type === 'SUCCESS' ? Object.keys(merchantStore.merchantData.cardConfigs ?? {})
    : []

  return (
    <>
      {userCardStore.type === 'SUCCESS' && merchantStore.type === 'SUCCESS' && (
        <OnboardingSheet
          merchantId={props.merchantId}
          firebaseDb={props.firebaseDb}
          cardsCollection={userCardStore.cardsData}
          auth={props.auth}
          creating={props.creating}
          onCreating={props.onCreating}
          configId={props.configId}
          cardConfigs={merchantStore.merchantData.cardConfigs}
        />
      )}
      {userCardStore.type === 'SUCCESS' &&
        merchantStore.type === 'SUCCESS' &&
        configIds.map((configId) =>
          userCardStore.cardsData?.[props.merchantId]?.[configId]?.cardId ?
            <LoyaltyCardSheet
              key={configId}
              merchantId={props.merchantId}
              firebaseDb={props.firebaseDb}
              user={props.user}
              id={userCardStore.cardsData?.[props.merchantId]?.[configId]?.cardId}
            />
          : undefined,
        )}
      {userCardStore.type === 'FAIL' && <>There was an error. Try again later...</>}
      {userCardStore.type === 'PENDING' && <>Loading...</>}
    </>
  )
}

export function LoggedOutMerchantOnboardingSheet(props: {
  user: null
  merchantId: string
  firebaseDb: FirebaseDb
  auth: WrappedAuth
  creating: boolean
  onCreating: (creating: boolean) => void
  configId: string | undefined
}) {
  const merchantStore = useMerchantStore(props.merchantId, props.firebaseDb)
  return (
    <>
      {merchantStore.type === 'SUCCESS' ?
        <OnboardingSheet
          configId={props.configId}
          merchantId={props.merchantId}
          firebaseDb={props.firebaseDb}
          cardsCollection={null}
          auth={props.auth}
          cardConfigs={merchantStore.merchantData.cardConfigs}
          creating={props.creating}
          onCreating={props.onCreating}
        />
      : undefined}
    </>
  )
}

type UserCardsState =
  | { type: 'FAIL' }
  | { type: 'PENDING' }
  | { type: 'CREATING' }
  | { type: 'SUCCESS'; cardsData: UserCardsCollection | null }

function useUserCardsStore(user: User, firebaseDb: FirebaseDb): UserCardsState {
  const ref = useMemo(() => {
    return firebaseDb.getRef(`users/${user.uid}/merchants`)
  }, [user, firebaseDb])
  const pendingRef = useMemo(() => {
    return firebaseDb.getRef(`users/${user.uid}/merchants`)
  }, [user, firebaseDb])

  const cardsData = useDatabaseNullableRefLiveValue<UserCardsCollection>({ ref })

  return useMemo(() => {
    if (cardsData instanceof Error) {
      return { type: 'FAIL' }
    } else if (cardsData === undefined) {
      return { type: 'PENDING' }
    } else {
      return {
        type: 'SUCCESS',
        cardsData,
      }
    }
  }, [cardsData])
}
