import { BrandedQRCard } from 'BrandedQRCardV2'
import { useMerchantStore } from 'UseMerchantStore'
import { FirebaseComponents } from 'components/common/Firebase'
import { pwaContext } from 'components/common/NotificationRegistrationProvider'
import { RoundButton } from 'components/common/RoundButton'
import { User } from 'firebase/auth'
import { useContext, useEffect } from 'react'

export function MerchantQRCardV2(props: {
  merchantId: string
  firebase: FirebaseComponents
  qrUrl: string
  onFlipClicked?: () => void
  configId: string
  user: User | undefined | null
}) {
  const merchantState = useMerchantStore(props.merchantId, props.firebase.firebaseDb)
  const { notificationRegisterer, pwaInstaller } = useContext(pwaContext)
  useEffect(() => {
    if (
      notificationRegisterer &&
      notificationRegisterer.notificationRegistrationState !== 'Not supported' &&
      notificationRegisterer.notificationRegistrationState !== 'Ready'
    ) {
      notificationRegisterer.requestForNotifications()
    }
  }, [notificationRegisterer])
  return (
    <>
      {merchantState.type === 'SUCCESS' && (
        <>
          {notificationRegisterer?.notificationRegistrationState === 'Ready' &&
            `You will receive Loyalty Notifications for this store. 👍`}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            !pwaInstaller?.appInstallState && (
              <RoundButton onClick={pwaInstaller?.installPwa}>
                Install App to get notifications
              </RoundButton>
            )}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            pwaInstaller?.appInstallState === 'Installing' && (
              <RoundButton aria-disabled={true}>Installing...</RoundButton>
            )}

          <div className='flex flex-col items-center justify-center gap-4 bg-white'>
            <div className='text-center'>
              <h2 className='text-4xl font-bold'>SCAN HERE</h2>
              <span className='text-sm font-semibold uppercase'>To join our rewards program</span>
            </div>
            <BrandedQRCard
              qrUrl={props.qrUrl}
              logo={merchantState.merchantData.logo}
              fontFamily={'LeagueSpartan'}
              theme={{ backgroundColor: 'black' }}
              onFlipClicked={props.onFlipClicked}
            />
          </div>
        </>
      )}
    </>
  )
}
