import { FirebaseApp } from 'firebase/app'
import { User } from 'firebase/auth'
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported as isMessagingSupported,
  onMessage,
} from 'firebase/messaging'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { toast } from 'react-toastify'
import { showDialog } from './Dialog'
import { FirebaseDb } from './Firebase'
import * as serviceWorker from './serviceWorkerRegistration'
import { usePromiseState } from './utils/reactUtils'

export type NotificationRegisterer = {
  requestForNotifications: () => void
  notificationRegistrationState:
    | 'Needs Permissions'
    | 'Ready'
    | 'Not supported'
    | 'Error'
    | 'Pending'
    | 'AwaitingRestart'
}

async function registerServiceWorker(): Promise<ServiceWorkerRegistration | 'AwaitingRestart'> {
  return new Promise((resolve, reject) => {
    serviceWorker.register({
      onAvailable: async (registration) => {
        resolve(registration)
      },
      onAwaitingRestart: async () => {
        resolve('AwaitingRestart')
      },
      onError: async (error) => {
        reject(error)
      },
    })
  })
}

async function updateToken(
  firebaseDb: FirebaseDb,
  messaging: Messaging,
  registration: ServiceWorkerRegistration,
  user: User | undefined,
) {
  let currentToken: string | undefined
  try {
    currentToken = await getToken(messaging, {
      serviceWorkerRegistration: registration,
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
    })
  } catch (e) {
    console.error(e)
  }
  if (currentToken && user) {
    await firebaseDb.getRef(`users/${user.uid}/fcm/${currentToken}`).set(true)
  }
  return currentToken
}

export function useNotificationsRegistration(
  firebaseApp: FirebaseApp,
  firebaseDb: FirebaseDb,
  user: User | undefined,
): NotificationRegisterer {
  const isSupported = usePromiseState(() => isMessagingSupported(), [firebaseApp])
  const messaging = useMemo(
    () => (isSupported ? getMessaging(firebaseApp) : undefined),
    [firebaseApp, isSupported],
  )
  const isPageVisible = usePageVisibility()
  const [registeredToken, setRegisteredToken] = useState<string>()
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<
    ServiceWorkerRegistration | 'Error' | 'AwaitingRestart'
  >()
  const [permission, setPermissions] = useState<NotificationPermission | undefined>(
    typeof Notification !== 'undefined' ? Notification.permission : undefined,
  )

  useEffect(() => {
    if (registeredToken && messaging) {
      const unsubscribe = onMessage(messaging, (payload) =>
        toast(payload.notification?.title, {
          type: 'info',
          autoClose: 2000,
          toastId: payload.messageId,
        }),
      )
      return () => {
        unsubscribe()
      }
    }
  }, [messaging, registeredToken])

  const tryRegisterServiceWorker = useCallback(async () => {
    try {
      const registration = await registerServiceWorker()
      setServiceWorkerRegistration(registration)
      return registration
    } catch (e) {
      setServiceWorkerRegistration('Error')
      return 'Error'
    }
  }, [])

  const tryRegisterToken = useCallback(
    async (messaging: Messaging) => {
      if (!isSupported) return
      setPermissions(Notification.permission)
      let registration = serviceWorkerRegistration
      if (!registration || registration === 'Error') {
        setServiceWorkerRegistration(undefined)
        registration = await tryRegisterServiceWorker()
      }
      if (registration === 'Error' || registration === 'AwaitingRestart') {
        return
      }
      const token = await updateToken(firebaseDb, messaging, registration, user)
      setRegisteredToken(token)
    },
    [serviceWorkerRegistration, firebaseDb, user, tryRegisterServiceWorker, isSupported],
  )

  useEffect(() => {
    isMessagingSupported().then((isSupported) => {
      const permission = typeof Notification !== 'undefined' ? Notification.permission : undefined
      if (permission === undefined) return
      if (!isSupported || permission !== 'granted') return
      setPermissions(permission)
      const messaging = getMessaging(firebaseApp)
      setServiceWorkerRegistration(undefined)
      tryRegisterServiceWorker().then(async (registration) => {
        if (registration === 'Error' || registration === 'AwaitingRestart') {
          return
        }
        const token = await updateToken(firebaseDb, messaging, registration, user)
        setRegisteredToken(token)
      })
    })
  }, [firebaseApp, tryRegisterServiceWorker, firebaseDb, user])

  const notificationRegistrationState = useMemo(() => {
    if (!isSupported) return 'Not supported'
    if (registeredToken && permission === 'granted') return 'Ready'
    if (serviceWorkerRegistration === 'Error') return 'Error'
    if (permission === 'granted') {
      if (serviceWorkerRegistration === 'AwaitingRestart') return 'AwaitingRestart'
      if (!serviceWorkerRegistration) return 'Pending'
    }
    return 'Needs Permissions'
  }, [isSupported, registeredToken, permission, serviceWorkerRegistration])

  const requestForNotifications = useCallback(() => {
    if (!messaging) return
    if (permission === 'granted') tryRegisterToken(messaging)
    else if (permission === 'denied')
      showDialog({
        title: 'Notification Permissions',
        children: (Red) => {
          return (
            <>
              It looks like notifications for playback are blocked.
              <Red>You&apos;ll need to enable Notification Permissions</Red> for Playback in your
              browser.
            </>
          )
        },
        positiveButtonProps: {
          text: 'OK',
          onClicked: async () => {
            await Notification.requestPermission().then(() => {
              tryRegisterToken(messaging)
            })
            return true
          },
        },
      })
    else {
      showDialog({
        title: 'Notifications',
        children: (Red) => {
          return (
            <>
              To receive notifications,{' '}
              <Red>we&apos;ll need to ask for Notification Permissions</Red>
            </>
          )
        },
        positiveButtonProps: {
          text: 'GO',
          onClicked: async () => {
            await Notification.requestPermission().then(() => {
              tryRegisterToken(messaging)
            })
            return true
          },
        },
      })
    }
  }, [permission, tryRegisterToken, messaging])

  return useMemo(
    () => ({
      requestForNotifications,
      notificationRegistrationState: notificationRegistrationState,
    }),
    [requestForNotifications, notificationRegistrationState],
  )
}
