import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import { useContext, useEffect, useMemo } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import DocumentTitle from 'react-document-title'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import NotificationBox from 'ui/NotificationBox'
import { useQueryString } from '../../components/QueryString'
import { useFirebase } from '../../components/common/Firebase'
import { pwaContext } from '../../components/common/NotificationRegistrationProvider'
import { useUserNullable } from '../../components/common/UseUser'
import { firebaseConfig } from '../../services/FirebaseConfig'
import { LoyaltyCardSheet } from './LoyaltyCardSheet'
import ProgramCard from 'pages/Home/components/ProgramCard'
import { useMerchantData } from 'hooks/useMerchantData'
import { useUserTeamsStore } from 'UseMerchantStore'
import { TokenCardConfig } from '../../../functions/src/data/common'
import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { ButtonIcon } from 'components/button/ButtonIcon'
import { PATH } from 'constants/routes'

const LAST_URL_KEY = 'last-url'

export function CardPage() {
  const [merchantId] = useQueryString('merchant')
  const { id: cardId } = useParams()
  const navigate = useNavigate()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const merchantData = useMerchantData(merchantId)

  const userTeams = useUserTeamsStore(user, firebaseDb)
  const { notificationRegisterer } = useContext(pwaContext)
  const state = useLoyaltyCardStore(cardId || null, firebaseDb)



  const cardName = useMemo(() => {
    if (state.type === 'SUCCESS') {
      return state.cardData.name
    }
  }, [state])

  const { cardConfigId, cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchantData?.merchant?.cardConfigs &&
        Object.entries(merchantData?.merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchantData?.merchant?.cardConfigs],
  )


  useEffect(() => {
    if (user && merchantId === user?.uid) {
      notificationRegisterer?.requestForNotifications()
    }
  }, [user, merchantId, notificationRegisterer])


    return (
      <DocumentTitle title={'Instant Loyalty - Card'}>
        <section className='layout h-[calc(100vh-5.5rem)] bg-light-mb-main'>
          <TwMainGridWrapper className='h-full'>
            {user && merchantId && (
              <NotificationBox
                user={user}
                firebaseDb={firebaseDb}
                merchantId={merchantId}
                className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
              />
            )}
            {merchantData?.merchant.cardConfigs && cardConfig && (
              <ProgramCard
                className='col-span-12 lg:col-span-5'
                cardConfig={cardConfig}
                merchant={merchantData?.merchant}
                uid={(user?.uid as string) || ''}
                isEdit={true}
                isPreview={true}
              />
            )}

            <div className='col-span-12 h-full rounded p-4 pb-6 shadow-md xl:col-span-4 xl:bg-white'>
              <h2 className='mb-4 px-2 text-xl font-semibold'>{cardName ? `${cardName}'s Card` : 'Reward'}</h2>
              {user === null && <Navigate to={`/merchant/${merchantId}`} />}
              {cardId && user !== null && (
                <div className='space-y-8'>
                  <LoyaltyCardSheet
                    merchantId={merchantId}
                    id={cardId}
                    firebaseDb={firebaseDb}
                    user={user}
                  />
                  <div className='flex flex-col gap-2'>
                    <p className='font-semibold'>Redeem point?</p>
                    <p>You’re about to redeem 1 point for Avery. Confirm?</p>
                  </div>
                  <div className='flex justify-start gap-x-4'>
                    <ButtonIcon
                      type='button'
                      content='Confirm'
                      className='min-w-28 bg-light-primary text-white hover:brightness-110'
                    />
                    <ButtonIcon
                      type='button'
                      onClick={() => navigate(PATH.HOME)}
                      content='Discard'
                      className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary
                hover:text-white'
                    />
                  </div>
                </div>
              )}
            </div>
          </TwMainGridWrapper>
        </section>
      </DocumentTitle>
    )
  }
