import React, { CSSProperties } from 'react'
import { HoverableBox, HoverableImage } from './UseHover'
import { BoxProps } from '@chakra-ui/react'

interface IconProps {
  height?: number | string
  width?: number | string
  src?: string
  alt?: string
  disableContextMenu?: boolean
  hover?: CSSProperties
}

/**
 * For when we really don't want context menus
 * @param props
 * @constructor
 */
export function BackgroundIcon(props: IconProps & BoxProps) {
  const { height, width, src, alt, disableContextMenu, hover, ...boxProps } = props
  if (!props.disableContextMenu)
    return (
      <HoverableImage
        hover={hover}
        draggable={false}
        {...boxProps}
        src={src}
        height={height}
        width={width}
        alt={props.alt}
      />
    )
  return (
    <HoverableBox
      {...boxProps}
      hover={hover}
      style={{
        height: props.height ?? props.width,
        width: props.width ?? props.height,
        ...props.style,
      }}>
      <div
        style={{
          backgroundImage: props.src && `url('${props.src}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%',
        }}
      />
    </HoverableBox>
  )
}
