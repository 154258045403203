import { MutableRefObject, RefCallback } from 'react'

export function compositeRefObject<T>(
  ...refObjects: (MutableRefObject<T | null | undefined> | undefined)[]
): RefCallback<T> {
  return (instance) => {
    refObjects.forEach((it) => {
      if (it) it.current = instance
    })
  }
}
