import clsx from 'clsx'
import { FirebaseComponents } from 'components/common/Firebase'
import MerchantAdressQRCard from 'components/modules/MerchantAdressQRCard'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import { User } from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'
import NotificationBox from 'ui/NotificationBox'
import { MerchantEntry, TokenCardConfig } from '../../../../functions/src/data/common'
import ProgramCard from './ProgramCard'

type DashboardMerchantProps = {
  user: User
  firebase: FirebaseComponents
  merchantId: string | undefined
  merchant: MerchantEntry | undefined
}

const DashboardMerchant: React.FC<DashboardMerchantProps> = ({
  user,
  firebase,
  merchantId,
  merchant,
}) => {
  const firebaseDb = firebase.firebaseDb
  const [selectedCardConfigId, setSelectedCardConfigId] = useState<string>()

  const { cardConfigId, cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchant?.cardConfigs &&
        Object.entries(merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchant?.cardConfigs],
  )
  console.log("🚀 ~ cardConfig:", cardConfig)

  const addressStores =
    (!cardConfig?.isLaunched &&
      cardConfig?.locations &&
      Array(cardConfig?.locations).length > 0 &&
      cardConfig?.locations.slice(0, 3)) ||
    cardConfig?.locations

  useEffect(() => {
    if (cardConfigId) setSelectedCardConfigId(cardConfigId)
  }, [cardConfigId])

  return (
    <TwMainGridWrapper className='h-full'>
      <NotificationBox
        user={user}
        firebaseDb={firebaseDb}
        merchantId={merchantId}
        className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
      />

      <ProgramCard
        className={clsx({
          'col-span-12 lg:col-span-8': cardConfig?.isLaunched,
          'col-span-12 lg:col-span-5': !cardConfig?.isLaunched,
        })}
        cardConfig={cardConfig}
        merchant={merchant}
        uid={(user?.uid as string) || ''}
        isEdit={!!cardConfig}
        isCreate={!cardConfig}
        isPreview={true}
      />

      {selectedCardConfigId && merchantId && (
        <MerchantAdressQRCard
          cardConfigId={selectedCardConfigId}
          merchantId={merchantId}
          firebase={firebase}
          user={user}
          addressStore={addressStores}
        />
      )}
    </TwMainGridWrapper>
  )
}
export default DashboardMerchant
