import { DataSnapshot } from 'firebase/database'
import { FirebaseDbReference } from '../components/common/Firebase'

export async function waitForDbMatch<T>(
  ref: FirebaseDbReference,
  predicate: (data: DataSnapshot) => boolean,
  timeout = 30000,
): Promise<T | undefined> {
  const checkMatchOrWait = async (timeout: number) => {
    return new Promise<T | undefined>(async (resolve, reject) => {
      try {
        const snapshot = await ref.get()
        if (predicate(snapshot)) {
          resolve(snapshot.val())
        }
      } finally {
        setTimeout(() => resolve(undefined), timeout)
      }
    })
  }
  const startTime = Date.now()
  let data: T | undefined
  while (!data) {
    data = await checkMatchOrWait(1000)
    if (data) return data
    if (Date.now() - startTime >= timeout) return undefined
  }
}
