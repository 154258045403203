import { Flex, Image } from '@chakra-ui/react'
import { User } from 'firebase/auth'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { MerchantEntry, UserCardsCollection } from '../functions/src/data/common'
import { MerchantState, useMerchantStore } from './UseMerchantStore'
import poweredByReclaim from './assets/img/poweredbyreclaim.svg'
import { FocusSpan, showDialog } from './components/common/Dialog'
import { EditableStringDiv } from './components/common/EditableStringDiv'
import { FirebaseDb, WrappedAuth } from './components/common/Firebase'
import { RoundButton } from './components/common/RoundButton'
import { useUserNullable } from './components/common/UseUser'
import { AddCardResult } from 'types/card.module'

export function OnboardingSheet(props: {
  merchantId: string
  firebaseDb: FirebaseDb
  auth: WrappedAuth
  cardsCollection: UserCardsCollection | null
  creating: boolean
  cardConfigs: MerchantEntry['cardConfigs']
  onCreating: (creating: boolean) => void
  configId: string | undefined
}) {
  const merchantState: MerchantState = useMerchantStore(props.merchantId, props.firebaseDb)
  const { user } = useUserNullable({ auth: props.auth })
  const configIds =
    props.configId ? [props.configId]
      : merchantState.type === 'SUCCESS' ? Object.keys(merchantState.merchantData.cardConfigs ?? {})
        : []

  return (
    <>
      {!!configIds.filter(
        (configId) => !props.cardsCollection?.[props.merchantId]?.[configId]?.cardId,
      ).length && (
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            direction={'column'}
            gap={40}>
            {merchantState.type === 'SUCCESS' && merchantState.merchantData.logo && (
              <>
                <Image
                  src={merchantState.merchantData.logo}
                  width={300}
                  maxWidth={'80vw'}
                  h={'fit'}
                />
                <span
                  style={{
                    fontFamily: 'Montserrat',
                    fontVariationSettings: `'wght' 700`,
                    fontSize: '35px',
                    whiteSpace: 'nowrap',
                  }}>
                  Get my card
                </span>
                {user !== undefined && (
                  <Flex
                    direction={'row'}
                    gap={8}>
                    {configIds
                      .filter(
                        (configId) => !props.cardsCollection?.[props.merchantId]?.[configId]?.cardId,
                      )
                      .map((configId) => {
                        return (
                          <CreateCardButton
                            key={configId}
                            creating={props.creating}
                            onCreating={props.onCreating}
                            configId={configId}
                            user={user}
                            addCard={merchantState.addCard}
                            firebaseDb={props.firebaseDb}
                            auth={props.auth}
                            merchantId={props.merchantId}
                            // cardTitle={props.cardConfigs[configId].title ?? 'loyalty'}
                            cardTitle={'loyalty'}
                            merchantName={merchantState.merchantData.name}
                          />
                        )
                      })}
                  </Flex>
                )}
              </>
            )}
            {merchantState.type === 'PENDING' && <>Loading...</>}
            {merchantState.type === 'FAIL' && <>Something went wrong. Try again later...</>}
            {merchantState.type === 'MISSING' && <>Vendor is missing...</>}
          </Flex>
        )}
      <PoweredByReclaim />
    </>
  )
}

const PoweredByReclaim = () => {
  return (
    <Flex>
      <Image
        src={poweredByReclaim}
        maxWidth={'100vw'}
      />
    </Flex>
  )
}

const promptForEmail = async (
  explanation: (span: FocusSpan) => ReactNode,
  placeholderText: string,
) => {
  return await new Promise<string | undefined>((resolve, reject) => {
    showDialog<string>(
      {
        children: (FocusSpan, state, setState, triggerPositiveButton, focusPositiveButton) => {
          return (
            <>
              {explanation(FocusSpan)}
              <EditableStringDiv
                autoFocus={true}
                style={{ backgroundColor: '#d9e1e3', color: 'black' }}
                placeholder={placeholderText}
                inputMode={'email'}
                onChange={setState}
                onEnter={triggerPositiveButton}
                aria-multiline={false}
              />
            </>
          )
        },
        positiveButtonProps: {
          text: 'OK',
          disabled: (state) => !state,
          onClicked: (state) => {
            if (state) {
              resolve(state)
              return true
            }
            return false
          },
        },
        negativeButtonProps: {
          text: 'CANCEL',
          onClicked: () => {
            resolve(undefined)
            return true
          },
        },
      },
      undefined,
    )
  })
}

const CreateCardButton = (props: {
  onCreating: (creating: boolean) => void
  creating: boolean
  firebaseDb: FirebaseDb
  configId: string
  auth: WrappedAuth
  merchantId: string
  user: User | null
  addCard: (user: User, email: string, configId: string) => Promise<AddCardResult | null>
  cardTitle: string
  merchantName: string
}) => {
  const navigate = useNavigate()
  return (
    <RoundButton
      alt={'create a card'}
      onClick={async () => {
        props.onCreating(true)
        let currentUser = props.user
        let email: string | undefined = currentUser?.email ?? undefined

        if (!email && currentUser) {
          email = (await props.firebaseDb.get(`users/${currentUser.uid}/email`)).val()
        }

        if (!email) {
          email = await promptForEmail(
            (FocusSpan) => (
              <p>
                Enter an email to help <FocusSpan>{props.merchantName}</FocusSpan> find your card
              </p>
            ),
            'Enter your email',
          )
        }

        if (email && !currentUser) {
          currentUser = (await props.auth.signInAnonymously()).user
        }

        if (email && currentUser) {
          await props.firebaseDb.getRef(`users/${currentUser.uid}/email`).set(email)
          await props.addCard(currentUser, email, props.configId)
        }

        props.onCreating(false)
      }}
      aria-disabled={props.creating}>
      {props.creating ? 'Creating card...' : `Create ${props.cardTitle} Card`}
    </RoundButton>
  )
}
