import { Button } from '@chakra-ui/react'
import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import clsx from 'clsx'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import useNotificationMerchant from 'hooks/useNotificationMerchant'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'util/formatDateTime'
import { TokenBalance } from '../../functions/src/data/common'

const nameRegex = /([\w.]+)@gmail\.com/

interface TextIcon {
  icon: React.ReactNode
  message: string | React.ReactNode
}
interface INotificationBoxProps {
  className?: string
  children?: React.ReactNode
  firebaseDb: FirebaseDb
  merchantId: string | undefined
  user: User
}

const NotificationBox: React.FC<INotificationBoxProps> = (props) => {

  const { data: dataList } = useNotificationMerchant({
    firebaseDb: props.firebaseDb,
    merchantId: props.merchantId,
    user: props.user,
  })

  const extractName = (text: string): string | null => {
    const match = text.match(nameRegex)
    return match ? match[1] : null
  }

  const revertTitle = (text: string): TextIcon => {
    if (text.includes('Card created for')) {
      return {
        icon: <UserCircleSVG className='h-8 w-8' />,
        message: (
          <span>
            New card created for <b>{extractName(text)}</b>
          </span>
        ),
      }
    } else if (text.includes('scanned your store qr-code')) {
      return {
        icon: <QRCodeSVG className='h-8 w-8' />,
        message: (
          <span>
            <b>{extractName(text)}</b> just scanned
          </span>
        ),
      }
    } else {
      return {
        icon: <></>,
        message: text,
      }
    }
  }

  // if (type === 'PENDING') {
  //   return <NotificationSkeleton />
  // }
  return (
    <div
      className={clsx(
        props.className,
        'no-scrollbar w-full overflow-y-auto rounded bg-white p-2 pt-4 shadow-md',
      )}>
      <h2 className='sticky top-0 z-10 mb-4 bg-white p-2 pt-0 text-xl font-semibold'>Notifications</h2>
      <div className='space-y-2'>
        {dataList && Array.isArray(dataList) &&
          Array(dataList).length > 0 &&
          dataList.map((it) => {
            const { icon, message } = revertTitle(it.title)
            return (
              <div key={it.timestamp} className='border-b-2 border-gray-200 pb-4'>
                <div className='flex items-start gap-x-2 rounded-md p-2'>
                  <div>{icon}</div>
                  <div className='text-slate-600'>
                    <div className='flex flex-col justify-start items-start gap-2 text-sm'>{message}</div>
                    <div style={{ fontSize: '0.8em' }}>{formatDate(it.timestamp)}</div>
                  </div>
                </div>
                {it.title && it.title.includes('created for') && (
                  <div className='text-center'>
                    <RewardButton
                      url={it.link}
                      firebase={props.firebaseDb}
                    />
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default NotificationBox

export const RewardButton: React.FC<{ url: string; firebase: FirebaseDb }> = ({
  url,
  firebase,
}) => {
  const naviage = useNavigate()
  const cardIdMatch = url.match(/\/card\/([^?]+)/)
  const cardId = cardIdMatch ? cardIdMatch[1] : ''
  const state = useLoyaltyCardStore(cardId, firebase)
  const [merchant, setMerchant] = useState<string>('')
  const [bankType, setBankType] = useState<TokenBalance>()

  useEffect(() => {
    if (state.type === 'SUCCESS' && (!merchant || !bankType)) {
      setMerchant(state.cardData.merchant)
      if (state.cardData.bankType === 'tokens') {
        setBankType(state.cardData.balance['default'])
      }
    }
  }, [merchant, state, bankType])


  return (
    <Button
      className='rounded-lg bg-[#bf997f] px-3 py-1 text-csm text-white transition-colors duration-150
        hover:cursor-pointer hover:brightness-110'
      onClick={() =>
        // cardActions?.type === 'tokens' && bankType && cardActions.onIncrementClicked?.('default') //todo handle increment points
        naviage(url)
      }>
      Reward
    </Button>
  )
}
