import { Spinner } from '@chakra-ui/react'
import clsx from 'clsx'
import { getDownloadURL, getStorage, ref as refStore, uploadBytesResumable } from 'firebase/storage'
import { ReactComponent as TrashSVG } from 'icons/trash.svg'
import { ChangeEvent, DragEvent, forwardRef, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useProgramStore } from 'stores/programStore'
import { CustomInputProps } from './CustomInput'

const UploadInput = forwardRef<HTMLInputElement, CustomInputProps<FieldValues>>(
  ({ name, onChange, error, setError, clearErrors, value, reset }, ref) => {
    const { setProgramBody, programBody } = useProgramStore()
    const [imageSrc, setImageSrc] = useState<string | null>(null)
    const [isDragActive, setIsDragActive] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif']
        if (!validTypes.includes(file.type)) {
          setError && setError(name, { type: 'manual', message: 'Unsupported file type' })
          return
        }

        if (file.size > 2 * 1024 * 1024) {
          // Limit file size to 2MB
          setError && setError(name, { type: 'manual', message: 'File size exceeds 2MB' })
          return
        }

        clearErrors && clearErrors(name)

        await uploadImageToServer(file)

        const reader = new FileReader()
        reader.onloadend = () => {
          const imgSrc = reader.result as string
          setImageSrc(imgSrc)
          return imgSrc
        }
        reader.readAsDataURL(file)
      }
      return file
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragActive(false)
      const data = {
        target: {
          files: event.dataTransfer.files,
        },
      }
      handleImageUpload(data as ChangeEvent<HTMLInputElement>)
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragActive(true)
    }

    const handleDragLeave = () => {
      setIsDragActive(false)
    }

    const handleClearImage = async () => {
      setProgramBody({ ...programBody, logo: '' })
      setImageSrc(null)
      // clearErrors && clearErrors(name)
      reset(name, { keepDirtyValues: true })
    }

    const uploadImageToServer = async (value: File) => {
      const storage = getStorage()
      const storageRef = refStore(storage, `images/${value.name}`)
      const uploadTask = uploadBytesResumable(storageRef, value)

      setLoading(true)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Calculate progress percentage
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`Upload is ${progress}% done`)
        },
        (error) => {
          // Handle errors here
          console.error('Error uploading image:', error)
          toast('Upload image failed', {
            type: 'error',
            position: 'bottom-right',
            autoClose: 2000,
          })
          setLoading(false)
        },
        async () => {
          // Handle successful uploads on complete
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref)
            onChange(url)
            toast('Image uploaded successfully', {
              type: 'success',
              position: 'bottom-right',
              autoClose: 2000,
            })
          } catch (error) {
            console.error('Error getting download URL:', error)
            toast('Failed to retrieve image URL', {
              type: 'error',
              position: 'bottom-right',
              autoClose: 2000,
            })
          } finally {
            setLoading(false)
          }
        },
      )
    }

    return (
      <>
        {!imageSrc && (
          <>
            <div
              className={clsx(
                'relative z-0 box-border cursor-pointer rounded-lg border-2 border-dashed p-4',
                { 'border-blue-500': isDragActive },
                { 'border-gray-300': !isDragActive },
                { 'border-red-500': error },
              )}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}>
              {(loading && (
                <div className='flex w-full items-end justify-center'>
                  <div className='h-5 w-5'>
                    <Spinner className='h-full w-full' />
                  </div>
                </div>
              )) || (
                <>
                  <input
                    ref={ref}
                    type='file'
                    accept='image/*'
                    name={name}
                    onChange={(e) => {
                      handleImageUpload(e)
                    }}
                    className='absolute left-0 top-0 z-0 h-full w-full cursor-pointer opacity-0'
                  />
                  <div className='text-center'>
                    {isDragActive ?
                      <p className='text-sm text-blue-500'>Drop the file here...</p>
                    : <p className='text-sm text-gray-500'>Drag & Drop or Click to Upload</p>}
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {imageSrc && (
          <div className='mb-4 flex items-center gap-x-6'>
            <div className='h-36 w-36'>
              <img
                src={imageSrc}
                alt='Uploaded'
                className='h-full w-full object-contain'
              />
            </div>

            <button
              type='button'
              onClick={handleClearImage}
              className='focus:shadow-outline rounded-lg bg-gray-300 p-2.5 font-bold text-white hover:bg-gray-500
                focus:outline-none'>
              <TrashSVG className='h-5 w-5 fill-white' />
            </button>
          </div>
        )}
      </>
    )
  },
)
UploadInput.displayName = 'UploadInput'
export default UploadInput
