import React, {
  CSSProperties,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { Box, BoxProps, Image, ImageProps } from '@chakra-ui/react'

const useHover = function <T extends HTMLElement>({
  enableMobile = true,
}: {
  enableMobile?: boolean
} = {}): [boolean, RefObject<T>] {
  const [isHovered, setIsHovered] = useState(false)
  const hoverRef = useRef<T>(null)

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  // const handleTouchStart = () => {
  //   setIsHovered(true)
  // }
  //
  // const handleTouchEnd = () => {
  //   setIsHovered(false)
  // }

  useLayoutEffect(() => {
    const ref = hoverRef.current
    if (ref && (!isMobile || enableMobile)) {
      const handleMouseOver = () => setIsHovered(true)
      const handleMouseOut = () => setIsHovered(false)

      ref.addEventListener('mouseenter', handleMouseEnter)
      ref.addEventListener('mouseleave', handleMouseLeave)
      ref.addEventListener('mouseover', handleMouseOver)
      ref.addEventListener('mouseout', handleMouseOut)

      return () => {
        ref.removeEventListener('mouseenter', handleMouseEnter)
        ref.removeEventListener('mouseleave', handleMouseLeave)
        ref.removeEventListener('mouseover', handleMouseOver)
        ref.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [handleMouseEnter, handleMouseLeave, enableMobile])

  return [isHovered, hoverRef]
}
export type HoverableProps = {
  hover?: CSSProperties
  onHoverChange?: (hovered: boolean) => void
}
export const HoverableBox = function (props: PropsWithChildren & HoverableProps & BoxProps) {
  const { onHoverChange, hover, ...boxProps } = props

  const [isHover, hoverRef] = useHover<HTMLDivElement>()
  useEffect(() => {
    onHoverChange?.(isHover)
  }, [onHoverChange, isHover])

  return (
    <Box
      {...boxProps}
      ref={hoverRef}
      style={{ ...props.style, ...(isHover ? props.hover : {}) }}>
      {props.children}
    </Box>
  )
}
export const HoverableImage = function (props: PropsWithChildren & HoverableProps & ImageProps) {
  const { onHoverChange, hover, ...imageProps } = props
  const [isHover, hoverRef] = useHover<HTMLImageElement>()

  useEffect(() => {
    onHoverChange?.(isHover)
  }, [onHoverChange, isHover])

  return (
    <Image
      {...imageProps}
      alt={imageProps.alt}
      ref={hoverRef}
      style={{ ...props.style, ...(isHover ? props.hover : {}) }}>
      {props.children}
    </Image>
  )
}

export default useHover
