import { useMerchantStore } from 'UseMerchantStore'
import MerchantCard from 'components/card/MerchantCard'
import { useMemo } from 'react'
import { ICard, LiveMerchantCardProps } from 'types/card.module'
import { MerchantEntry } from '../../../../functions/src/data/common'

const LiveMerchantCard: React.FC<LiveMerchantCardProps> = ({
  className,
  merchantId,
  firebaseDb,
  onClick,
  cardConfigId
}) => {
  const merchantState = useMerchantStore(merchantId, firebaseDb)
  const merchantEntry =
    merchantState.type === 'SUCCESS' ? (merchantState.merchantData as MerchantEntry) : null


  const merchantConfig: ICard | null = useMemo(() => {
    if (!merchantEntry) return null

    const defaultCardConfig = merchantEntry.cardConfigs?.[cardConfigId]
    if (!defaultCardConfig) return null
    const defaultTemplate = defaultCardConfig?.templates[defaultCardConfig.defaultTemplateId]

    const points =
      (defaultTemplate && defaultTemplate.templateType === 'tokens' &&
        defaultTemplate.lineMax &&
        Array.from({ length: defaultTemplate.lineMax as number }, (_, i) => i + 1)) ||
      []

    return {
      logo: merchantEntry.logo,
      name: merchantEntry.name,
      primaryColor: defaultTemplate?.primaryColor,
      secondaryColor: defaultTemplate?.secondaryColor,
      isCreate: false,
      points: points,
    } satisfies ICard
  }, [merchantEntry, cardConfigId])

  if (!merchantConfig && merchantState.type === 'PENDING') {
    return <div>Loading...</div>
  }

  return merchantConfig ? (
    <MerchantCard
      onClick={() => onClick && onClick(merchantConfig)}
      merchantConfig={merchantConfig}
    />
  ) : <></>
}
export default LiveMerchantCard
