import { Flex, IconButton } from '@chakra-ui/react'
import { User } from 'firebase/auth'
import React, { useMemo } from 'react'
import DocumentTitle from 'react-document-title'
import { FaHome } from 'react-icons/fa'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { CardRefsByConfig } from '../functions/src/data/common'
import { LoyaltyCardSheet } from './LoyaltyCardSheet'
import { useUserTeamsStore } from './UseMerchantStore'
import { ScrollBox } from './components/ScrollBox'
import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useFirebase,
} from './components/common/Firebase'
import { useUserNullable } from './components/common/UseUser'
import { firebaseConfig } from './services/FirebaseConfig'

export function CustomerPage() {
  const { customerId } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const navigate = useNavigate()
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <ScrollBox
        direction={'vertical'}
        height={'100%'}>
        {customerId === user?.uid && <Navigate to={'/'} />}
        <Flex
          direction={'column'}
          width={'100%'}
          // height={'100%'}
          alignItems={'center'}
          gap={20}>
          <Flex direction={'row'}>
            <IconButton
              onClick={() => navigate('/')}
              border={'none'}
              padding={10}
              borderRadius={5}
              // backgroundColor={'rgb(0,0,0,0)'}
              aria-label={'home'}
              icon={<FaHome />}
            />
            {user !== undefined && <SignIn />}
          </Flex>
          {userTeams.type === 'SUCCESS' &&
            userTeams.teams &&
            customerId &&
            Object.entries(userTeams.teams.permissions)
              .filter(([_, member]) => member?.permission === 'owner')
              .map(([merchantId]) => (
                <MerchantCustomerCards
                  key={merchantId}
                  merchantId={merchantId}
                  customerId={customerId}
                  firebaseDb={firebaseDb}
                  user={user}
                />
              ))}
        </Flex>
      </ScrollBox>
    </DocumentTitle>
  )
}

const MerchantCustomerCards = (props: {
  merchantId: string
  customerId: string
  firebaseDb: FirebaseDb
  user: User | undefined | null
}) => {
  const customerCards = useCustomerCardsStore(props.merchantId, props.customerId, props.firebaseDb)
  return (
    <>
      {customerCards.type === 'SUCCESS' &&
        customerCards.cardsData &&
        Object.values(customerCards.cardsData).map((cardRef) => (
          <LoyaltyCardSheet
            key={cardRef.cardId}
            merchantId={props.merchantId}
            firebaseDb={props.firebaseDb}
            user={props.user}
            id={cardRef.cardId}
          />
        ))}
      {customerCards.type === 'FAIL' && 'Something went wrong'}
      {customerCards.type === 'PENDING' && 'Loading Customer...'}
    </>
  )
}

function useCustomerCardsStore(merchantId: string, customerUserId: string, firebaseDb: FirebaseDb) {
  const ref = useMemo(() => {
    return firebaseDb.getRef(`merchantsPrivate/${merchantId}/users/${customerUserId}`)
  }, [merchantId, customerUserId, firebaseDb])

  const cardsData = useDatabaseNullableRefLiveValue<CardRefsByConfig>({ ref })

  return useMemo(() => {
    if (cardsData instanceof Error) {
      return { type: 'FAIL' } as const
    } else if (cardsData === undefined) {
      return { type: 'PENDING' } as const
    } else {
      return {
        type: 'SUCCESS',
        cardsData,
      } as const
    }
  }, [cardsData])
}
