import { Button } from '@chakra-ui/react'
import { ButtonIcon } from 'components/button/ButtonIcon'
import Card from 'components/card/Card'
import { PATH } from 'constants/routes'
import { ReactComponent as ShoppingCardSVG } from 'icons/shopping-cart.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProgramStore } from 'stores/programStore'
import { MerchantEntry, TokenCardConfig } from '../../../../functions/src/data/common'
import { cn } from 'components/common/utils/tailwindUtils'

interface IProgramCard {
  merchant?: MerchantEntry
  isCreate?: boolean
  isEdit?: boolean
  isPreview?: boolean
  uid?: string
  cardConfig: TokenCardConfig | undefined
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}

const ProgramCard: React.FC<IProgramCard> = ({
  isCreate = false,
  merchant,
  isEdit = false,
  isPreview = false,
  uid,
  cardConfig,
  className
}) => {

  const [isHaveData, setIsHaveData] = useState<boolean>(false)
  const navigate = useNavigate()
  const { programBody } = useProgramStore()
  const {
    name: nameBody,
    primaryColor: primaryColorBody,
    secondaryColor: secondaryColorBody,
    logo: logoBody,
    reward,
  } = programBody


  const { isLaunched, templates } = cardConfig ?? {}

  const {
    lineMax,
    primaryColor,
    secondaryColor,
    trial,
    target: count,
  } = (templates && cardConfig && templates[cardConfig?.defaultTemplateId]) || {}

  const points =
    ((reward || lineMax) &&
      Array.from({ length: (lineMax as number) || reward }, (_, i) => i + 1)) ||
    []

  const onClickToCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    navigate(PATH.PROGRAM_CREATE)
  }

  useEffect(() => {
    if (cardConfig !== undefined) { 
      setIsHaveData(true)
    }
  }, [cardConfig])


  return (
    <div className={cn('lg:h-auto p-4 pb-6 shadow-md bg-white box-content h-min rounded-md', className)}>
      <div className='flex items-start justify-between'>
        <h2 className='mb-4 text-xl font-semibold'>Program</h2>
        <>
          {isEdit && !isCreate && !isLaunched && uid && (
            <>
              <div className='flex items-center gap-x-2 rounded-xl bg-yellow-400 px-1'>
                <ShoppingCardSVG className='h-5 w-5' />
                <p>Inactive</p>
              </div>
              <Button onClick={() => navigate(PATH.PROGRAM_EDIT.replace(':id', uid))}>Edit</Button>
            </>
          )}
        </>
      </div>
      <Card
        primaryColor={(primaryColorBody || primaryColor) as string}
        secondaryColor={(secondaryColorBody || secondaryColor) as string}
        name={(nameBody || merchant?.cardConfigs && merchant.defaultCardConfigId && merchant?.cardConfigs[merchant.defaultCardConfigId].title || undefined)}
        logo={(merchant?.logo || logoBody) as string}
        isCreate={isCreate}
        onClick={onClickToCreate}
        points={points}
        isHaveData={isHaveData}
      />
      {!isLaunched && isPreview && isHaveData && (
        <div className='mt-8 space-y-4 md:col-span-2'>
          <p className='text-light-secondary'>Your program is not active.</p>
          <p>Launch your program today to start rewarding your customers</p>
          <div className='flex justify-start gap-x-4'>
            <ButtonIcon
              type='submit'
              content='Test'
              className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary
                hover:text-white'
            />
            <ButtonIcon
              type='button'
              content='Launch'
              className='min-w-28 bg-light-primary text-white hover:brightness-110'
            />
          </div>
          {trial !== null && count !== null && (
            <div>
              <p className='text-sm'>
                <span className='rounded-xl bg-yellow-400 p-1 px-2 text-sm'>{`${trial}/${count}`}</span>{' '}
                Test cards left
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ProgramCard