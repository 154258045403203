import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useFirebase } from './components/common/Firebase'
import { useUserNullable } from './components/common/UseUser'
import DocumentTitle from 'react-document-title'
import { Flex, IconButton } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useMerchantInviteStore, useMerchantStore } from './UseMerchantStore'
import { firebaseConfig } from './services/FirebaseConfig'
import { MerchantDetailsCard } from './MerchantPage'
import { RoundButton } from './components/common/RoundButton'
import { FaHome } from 'react-icons/fa'

export function MerchantTeamInvitePage() {
  const { id: merchantId, token } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn, navigateToSignIn } = useUserNullable({ auth: loyaltyAuth })
  const merchantState = useMerchantStore(merchantId, firebaseDb)
  const { acceptInvite, joining } = useMerchantInviteStore(firebaseDb)
  const [joinResult, setJoinResult] = useState<boolean>()
  const navigate = useNavigate()
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <Flex
        direction={'column'}
        width={'100%'}
        height={'100%'}
        alignItems={'center'}
        gap={20}
        justifyContent={'center'}>
        {merchantState.type === 'SUCCESS' && (
          <>
            <MerchantDetailsCard merchantEntry={merchantState.merchantData} />
            You&apos;ve been invited to be an admin of {merchantState.merchantData.name}
            <Flex direction={'row'}>
              <IconButton
                onClick={() => navigate('/')}
                border={'none'}
                padding={10}
                borderRadius={5}
                // backgroundColor={'rgb(0,0,0,0)'}
                aria-label={'home'}
                icon={<FaHome />}
              />
              {user !== undefined && <SignIn />}
            </Flex>
            {user && !user.isAnonymous && merchantId && (
              <RoundButton
                alt={'Take me to my store'}
                color={'white'}
                backgroundColor={'black'}
                aria-disabled={joining || joinResult !== undefined}
                onClick={async () => {
                  setJoinResult(await acceptInvite(merchantId, user.uid, token!))
                }}>
                {joining && joinResult === undefined ? 'Accepting...' : ''}
                {!joining && joinResult === undefined ? 'Accept Invite' : ''}
                {joinResult !== undefined && joinResult ? 'Success!' : ''}
                {joinResult !== undefined && !joinResult ? 'Error...' : ''}
              </RoundButton>
            )}
            {joinResult !== undefined && joinResult && merchantId && <Navigate to={'/'} />}
          </>
        )}
      </Flex>
    </DocumentTitle>
  )
}
