import { useCallback, useState } from 'react'
import * as QueryString from 'querystring'

export const getQueryStringValue = (
  key: string,
  queryString = window.location.search,
): string | undefined => {
  const values = QueryString.parse(queryString.substring(1))
  const value = values[key]
  if (!value) return undefined
  if (typeof value === 'string') return value
  return value[0]
}

const setQueryStringValue = (
  key: string,
  value: string | undefined,
  queryString = window.location.search,
) => {
  const values = QueryString.parse(
    queryString.startsWith('?') ? queryString.replace('?', '') : queryString,
  )

  let newValues: { [p: string]: string | string[] | undefined }
  if (value === undefined) {
    newValues = { ...values }
    delete newValues[key]
  } else {
    newValues = { ...values, [key]: value }
  }
  const newQsValue = QueryString.stringify(newValues)
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}
const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl =
    window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue

  window.history.pushState({ path: newurl }, '', newurl)
}

export function useQueryString(
  key: string,
  initialValue?: string,
): [string | undefined, (string: string | undefined, forceRender?: boolean) => void] {
  const [value, setValue] = useState<string | undefined>(getQueryStringValue(key) || initialValue)
  const onSetValue = useCallback(
    (newValue: string | undefined, forceRender?: boolean) => {
      if (forceRender || newValue !== value) {
        setValue(newValue)
        setQueryStringValue(key, newValue)
      }
    },
    [key, value],
  )

  return [value, onSetValue]
}
