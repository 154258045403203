import { useEffect, useState } from 'react'

const useGreeting = () => {
  const [greeting, setGreeting] = useState('')

  useEffect(() => {
    const getCurrentGreeting = () => {
      const currentHour = new Date().getHours()
      if (currentHour < 12) {
        return 'Good Morning'
      } else if (currentHour < 18) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    }

    setGreeting(getCurrentGreeting())

    const interval = setInterval(() => {
      setGreeting(getCurrentGreeting())
    }, 60000) // Update greeting every minute

    return () => clearInterval(interval)
  }, [])

  return greeting
}

export default useGreeting
