import { useUserTeamsStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import { DraggableScrollBox } from 'components/DraggableScrollBox'
import RenderIf from 'components/RenderIf'
import Card from 'components/card/Card'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { useMerchantData } from 'hooks/useMerchantData'
import LiveMerchantCard from 'pages/Merchant/components/LiveMerchantCard'
import React, { useCallback, useState } from 'react'
import { firebaseConfig } from 'services/FirebaseConfig'
import { ICard } from 'types/card.module'
import DashboardMerchant from './DashboardMerchant'

const Dashboard: React.FC = () => {
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { user } = useUserNullable({ auth: firebase.auth })

  const [showMerchantCard, setShowMerchantCad] = useState<ICard | null>()

  const merchantData = useMerchantData(user?.uid || '')
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const state = useUserCardsStore(user, firebaseDb)

  const handleShowCard = useCallback(
    (e: ICard) => {
      if (e.name === showMerchantCard?.name) {
        setShowMerchantCad(null)
        return
      }
      setShowMerchantCad(e)
    },
    [showMerchantCard],
  )
  if (merchantData === undefined) {
    return <SkeletonHome />
  }

  if (userTeams.teams && user) {
    const merchantId = userTeams.teams.permissions ? Object.keys(userTeams.teams.permissions) : ''
    return (
      <section className='layout h-[calc(100vh-5.5rem)] bg-light-mb-main'>
        <DashboardMerchant
          merchantId={merchantId[0]}
          firebase={firebase}
          user={user}
          merchant={merchantData?.merchant}
        />

      </section>
    )
  }

  return (
    <section className='layout h-[calc(100vh-5.5rem)] max-w-[31.25rem] bg-light-mb-main'>
      <RenderIf isTrue={!!user?.uid}>
        <div className='mt-8 space-y-8 px-2.5'>
          <div>
            <h4 className='text-xl font-semibold capitalize'>My programs</h4>
          </div>
          {state.type === 'SUCCESS' && !!Object.entries(state.cardsData).length && (
            <DraggableScrollBox direction={'horizontal'}>
              <div className='no-scrollbar flex w-full gap-3 lg:justify-center'>
                {Object.entries(state.cardsData).flatMap(([merchantId, cardRefsByConfig]) => {
                  return Object.entries(cardRefsByConfig).map(([cardConfigId, cardRef]) => ({
                    merchantId,
                    cardConfigId,
                    cardId: cardRef.cardId,
                  }))
                }).map(({ merchantId, cardId, cardConfigId }) => {
                  return (
                    <LiveMerchantCard
                      onClick={handleShowCard}
                      key={`${merchantId}-${cardConfigId}`}
                      cardConfigId={cardConfigId}
                      cardId={cardId}
                      merchantId={merchantId}
                      firebaseDb={firebaseDb}
                    />
                  )
                })}
              </div>
            </DraggableScrollBox>
          )}
          <div>
            {showMerchantCard && (
              <Card
                {...showMerchantCard}
                isHaveData={!!showMerchantCard}
              />
            )}
          </div>
        </div>
      </RenderIf>

      {state.type === 'NO_CARD' && (
        <div className='mt-4 text-center'>
          Loading Loyalty programs in your area...
          <br />
          <br />
          Scan a Loyalty QR to get started
        </div>
      )}
    </section>
  )
}

export default Dashboard
